import { Input, Component, Output, EventEmitter, OnInit } from '@angular/core';
import { GenericService } from '../../../core/services/generic.service';
// import { Tab, Longtab, Service } from './app.service';

@Component({
  selector: 'kt-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent implements OnInit {
  @Input() buttonType: string = 'submit'; // submit - reset - custom - back

  @Input() nameText: string;

  @Input() disabled: boolean;

  @Output() selectButton: EventEmitter<any> = new EventEmitter();

  useSubmitBehavior: boolean;

  constructor(private service: GenericService) {}

  ngOnInit(): void {
    this.useSubmitBehavior = this.buttonType === 'submit';
  }

  click(e) {
    //console.log('Button clicked:-', this.buttonType);
    this.selectButton.emit(this.buttonType);
  }
}
