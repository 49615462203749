import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  AfterViewInit,
  OnDestroy,
  ChangeDetectorRef,
  Output,
  EventEmitter,
} from '@angular/core';
import KTWizard from '../../../../assets/js/components/wizard';
import { KTUtil } from '../../../../assets/js/components/util';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
  selector: 'kt-wizard4',
  templateUrl: './wizard4.component.html',
  styleUrls: ['./wizard4.component.scss'],
})
export class Wizard4Component implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('wizard', { static: true }) el: ElementRef;

  @Input() title: string;

  @Input() menu: any[] = [];

  @Input() previous: string = 'Back';

  @Input() next: string = 'Next';

  @Input() submit: string = 'Generate';
  @Input() alertMSG: string =
    'Check all data. Something bad';

  @Output() w4Emit: EventEmitter<any> = new EventEmitter();
  submitted = false;

  wizard: any;

  @Input() validation: boolean = false;
  ngOnInit() {}
  constructor(private cdr: ChangeDetectorRef) {}
  ngAfterViewInit(): void {
    this.init();
  }

  init() {
    // Initialize form wizard
    this.wizard = new KTWizard(this.el.nativeElement, {
      startStep: 1,
      clickableSteps: false,
      navigation: false,
    });

    // Validation before going to next page
    let ec = this;
    // Change event
    this.wizard.on('change', (wizardObj) => {
      // //console.log("chegou change");

      setTimeout(() => {
        //     //console.log("AQUIIIIII", wizardObj)
        //  wizardObj.stop();
        KTUtil.scrollTop();
        ec.cdr.detectChanges();
      }, 500);
    });
    const wizardEl = document.querySelector('#kt_wizard_v4');
    const prevButton = wizardEl.querySelector(
      '[data-wizard-type="action-prev"]'
    );
    const nextButton = wizardEl.querySelector(
      '[data-wizard-type="action-next"]'
    );
    // Custom navigation handlers
    prevButton.addEventListener('click', function () {
      // Go back to the previouse step
      // //console.log("VOLTOU");
      // //console.log("STEPPPPP", ec.wizard.getStep());
      ec.w4Emit.emit({ step: ec.wizard.getStep(), submit: false });
      //	ec.wizard.goPrev();
    });
    nextButton.addEventListener('click', function () {
      //alert("aquioiii yeah->" + ec.validation)

      // do some validation here

      // Go to the next step only if validation is success
      console.log('chegou aqui', ec);
      if (ec.validation) {
        ////console.log('validou')
        //	ec.wizard.goNext();
        //  //console.log("STEPPPPP", ec.wizard.getStep());
        ec.w4Emit.emit({ step: ec.wizard.getStep(), submit: false });
      } else {
        // //console.log("Pasrou");
        ec.wizard.goPrev();

        //ec.wizard.stop();
        setTimeout(() => {
          Swal.fire({
            text: ec.alertMSG,
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: 'Ok',
            customClass: {
              confirmButton: 'btn font-weight-bold btn-light',
            },
          }).then(function () {
            //	KTUtil.scrollTop();
          });
        }, 300);
      }
    });
  }

  onSubmit() {
    this.submitted = true;
    //console.log('SUBMITTTT');
    this.w4Emit.emit({ step: this.wizard.getStep(), submit: true });
  }

  ngOnDestroy() {
    this.wizard = undefined;
  }
  wizardEvent(e) {
    //console.log('EVENT WIZARD 4', e);
  }
}
