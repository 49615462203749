<div class="brand flex-column-auto" id="kt_brand" [ngClass]="brandClasses">
  <!--begin::Logo-->
  <a routerLink="/" class="brand-logo">
    <img alt="Logo" [attr.src]="headerLogo" />
  </a>
  <!--end::Logo-->

  <ng-container *ngIf="asideSelfMinimizeToggle">
    <button class="brand-toggle btn btn-sm px-0" id="kt_aside_toggle">
      <span
        [inlineSVG]="
          './assets/media/svg/icons/Navigation/Angle-double-left.svg'
        "
        cacheSVG="true"
        class="svg-icon svg-icon-xl"
      ></span>
    </button>
  </ng-container>
</div>
<!--end::Brand-->

<!--begin::Aside Menu-->
<div class="aside-menu-wrapper flex-column-fluid" id="kt_aside_menu_wrapper">
  <ng-container *ngIf="disableAsideSelfDisplay">
    <!--begin::Header Logo-->
    <a routerLink="/">
      <img alt="Logo" [attr.src]="headerLogo" />
    </a>
    <!--end::Header Logo-->
  </ng-container>
  <!--begin::Menu Container-->
  <div
    id="kt_aside_menu"
    class="aside-menu"
    [ngClass]="asideMenuCSSClasses"
    data-menu-vertical="1"
    [attr.data-menu-scroll]="asideMenuScroll"
    [attr.data-menu-dropdown]="asideMenuDropdown"
    data-menu-dropdown-timeout="500"
  >
    <!-- begin::Menu Nav -->
    <ul class="menu-nav" [ngClass]="ulCSSClasses">
      <!-- begin::section -->
      <div class="menu-nav" *ngFor="let child of items; let i = index">
        <li (click)="clickMenu(child.collapsed=!child.collapsed, i)" class="menu-section" *ngIf="child.section">

          <h4 class="menu-text menu-text-section">{{ child.section }}</h4>
          <i class="menu-icon flaticon-more-v2"></i> 

        </li>
        <li
          *ngIf="!child.section && !child.submodel"
          
          [ngClass]="child.cssClass"
          aria-haspopup="true"
          routerLinkActive="menu-item-active"
        >
          <a
            class="menu-link"
            routerLink="{{ child.page }}"
            routerLinkActive="active"
          >
            <i class="svg-icon menu-icon fa {{ child.icon }}"></i>
            <span class="menu-text">{{ child.translate }}</span>
          </a>
        </li>

        <li
          *ngIf="child.submodel"
          [ngClass]="child.cssClass"
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <a href="javascript:;" class="menu-link menu-toggle">
            <span class="svg-icon menu-icon">
              <i class="svg-icon menu-icon fa {{ child.icon }}"></i>
            </span>
            <span class="menu-text">{{ child.submodel }}</span>
            <i class="menu-arrow"></i>
          </a>
          <div class="menu-submenu">
            <i class="menu-arrow"></i>
            <ul class="menu-subnav">
              <ng-container *ngFor="let m of child.list">
                <li *ngIf="m.translate" class="menu-item" aria-haspopup="true">
                  <a routerLink="{{ m.page }}" class="menu-link">
                    <i class="menu-bullet menu-bullet-dot">
                      <span></span>
                    </i>
                    <span class="menu-text">{{ m.translate }}</span>
                  </a>
                </li>
              </ng-container>
              <li
                *ngIf="child.submodel2 && child.submodel2!=='none'"
                [ngClass]="child.cssClass"
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <a href="javascript:;" class="menu-link menu-toggle">
                  <i class="menu-bullet menu-bullet-dot">
                    <span></span>
                  </i>
                  <span class="menu-text">{{ child.submodel2 }}</span>
                  <i class="menu-arrow"></i>
                </a>
                <div class="menu-submenu">
                  <i class="menu-arrow"></i>
                  <ul class="menu-subnav">
                    <ng-container *ngFor="let m2 of child.list2">
                      <li
                        *ngIf="m2.translate"
                        class="menu-item"
                        aria-haspopup="true"
                      >
                        <a routerLink="{{ m2.page }}" class="menu-link">
                          <i class="menu-bullet menu-bullet-dot">
                            <span></span>
                          </i>
                          <span class="menu-text">{{ m2.translate }}</span>
                        </a>
                      </li>
                    </ng-container>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </li>

      </div>
    </ul>
  </div>
</div>
