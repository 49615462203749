import {
  Component,
  OnInit,
  Input,
  //  ViewChild,
  AfterViewInit,
  // AfterViewChecked,
  // AfterContentInit,
  // AfterContentChecked,
  // ViewChildren,
  // QueryList,
  ChangeDetectorRef,
  Output,
  EventEmitter,
  OnChanges,
} from '@angular/core';
import 'brace';
import 'brace/theme/eclipse';
import 'brace/mode/javascript';
// import { GenericService } from "../../_metronic/core/services/generic.service";
// import { FrmComponent } from "../../_metronic/shared/devextreme/frm/frm.component";
// import { isNotUndefined } from "../../_metronic/core/util/functions";
import { confirm, alert } from 'devextreme/ui/dialog';
import { Subscription } from 'rxjs';
// import * as moment from "moment";
import notify from 'devextreme/ui/notify';
import { DomainOrderService } from './domain-order.service';
import { DataShareService } from '../../_metronic/core/services/datashare.service';

@Component({
  selector: 'kt-domain-order',
  templateUrl: './domain-order.component.html',
  styleUrls: ['./domain-order.component.scss'],
})
export class DomainOrderComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() screenData;

  @Input() parentData;

  @Output() popUpClose: EventEmitter<any> = new EventEmitter();

  private unsubscribe: Subscription[] = [];

  storeInitialData: any = [];

  orders: any;

  formIdField: any;

  buttonNames: any;

  constructor(
    private service: DomainOrderService,
    private dataShareService: DataShareService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    const subscr = this.dataShareService
      .getPopupCloseBehavior()
      .subscribe((close) => {
        this.discardChanges();
      });
    this.unsubscribe.push(subscr);
  }

  ngAfterViewInit() {}

  ngOnChanges() {
    if (this.screenData) {
      //console.log(this.screenData, this.parentData);
      this.buttonNames = this.screenData.formButtons;
      this.formIdField = this.screenData.screen[0].dataField;
      const param = {};
      param[this.formIdField] = this.parentData[this.formIdField];
      // this.orders = this.service.getPlannedTasks();
      this.service.getData(param).then(async (data: any) => {
        this.orders = data[0].models_order;
        if (this.orders.length === 0) {
          this.peopleDefaultModel();
        } else {
          this.orders.forEach((element) => {
            element.text = element.text ? element.text : element.model;
          });
          this.setOrderData(this.orders);
          //console.log('Orders>>>', this.orders);
          this.storeInitialData = [...this.orders];
          this.cdr.detectChanges();
        }
      });
    }
  }

  peopleDefaultModel() {
    this.service.getPeopleDefaultModel().then(async (data: any) => {
      this.orders = data;
      this.setOrderData(this.orders);
      //console.log('Orders>>>', this.orders);
      this.storeInitialData = [...this.orders];
      this.cdr.detectChanges();
    });
  }

  discardChanges() {
    //console.log('Discard Changes');
    if (this.checkObjectSame(this.storeInitialData, this.orders)) {
      //console.log('No Changes');
      return false;
    }
    //console.log('Changes');
    return true;
  }

  checkObjectSame(initObject, finalObject) {
    //console.log(this.storeInitialData);
    this.setOrderData(finalObject);
    for (let i = 0; i < initObject.length; i++) {
      if (initObject[i].order != finalObject[i].order) {
        return false;
      }
    }
    return true;
  }

  cancelClick() {
    //console.log('Cancel');
    // this.discardChanges();
    if (this.discardChanges()) {
      const result = confirm(
        'Do you want to proceed without saving ?',
        'Alert!'
      );
      result.then((dialogResult) => {
        if (dialogResult) {
          this.popUpClose.emit();
        }
      });
    } else {
      this.popUpClose.emit();
    }
  }

  submitData() {
    this.setOrderData(this.orders);
    //console.log('Submity', this.orders);
    const params: any = {};
    params[this.formIdField] = this.parentData[this.formIdField];
    params.order = this.orders;
    this.service
      .updateData(params, this.parentData[this.formIdField])
      .then((res: any) => {
        //console.log(res);
        if (res.status === 'ok') {
          this.popUpClose.emit();
        } else {
          notify(res.error, 'error', 5000);
        }
      });
  }

  setOrderData(dataArray) {
    if (!dataArray || dataArray.length === 0) return;
    for (let i = 0; i < dataArray.length; i++) {
      dataArray[i].order = i;
    }
  }

  onDragStart(e) {
    //console.log('onDragStart', e);
    e.itemData = e.fromData[e.fromIndex];
  }

  onAdd(e) {
    //console.log('onAdd', e);
    e.toData.splice(e.toIndex, 0, e.itemData);
  }

  onRemove(e) {
    //console.log('onRemove', e);
    e.fromData.splice(e.fromIndex, 1);
  }

  onButtonClick(name) {
    //console.log('button:-', name);
    switch (name) {
      case 'submit':
        this.submitData();
        break;

      case 'back':
        this.cancelClick();
        break;

      default:
        break;
    }
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }
}
