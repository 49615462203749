import {
  Component,
  Input,
  ElementRef,
  Renderer2,
  forwardRef,
  ContentChild,
  OnInit,
  AfterContentInit,
} from '@angular/core';
import {
  FormControlName,
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';

@Component({
  selector: 'kt-radiocheckbox',
  host: {
    '(change)': 'onChange($event.target.checked)',
    '(blur)': 'onTouched()',
  },
  templateUrl: './radiocheckbox.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => RadioCheckboxComponent),
    },
  ],
})
export class RadioCheckboxComponent
  implements OnInit, AfterContentInit, ControlValueAccessor
{
  @Input() type: string = 'checkbox';

  @Input() ktClass: string = '';

  @Input() label: string;

  @Input() name: string;

  @Input() data: string;

  @Input() errorMessage: string;

  @Input('value') _value = false;

  onChange = (_: any) => {};

  onTouched = () => {};

  input: any;

  @ContentChild(FormControlName, { read: false, static: true })
  control: FormControlName;

  constructor(private _renderer: Renderer2, private _elementRef: ElementRef) {}

  ngOnInit() {}

  ngAfterContentInit() {
    this.input = this.control;
    //	//console.log("this control", this.control);
    if (this.input === undefined) {
      throw new Error(
        'this componenet need to be used with a ngModel or FormControlName Directive'
      );
    }
  }

  get value() {
    return this._value;
  }

  set value(val) {
    this._value = val;
    this.onChange(val);
    this.onTouched();
  }

  writeValue(value: any): void {
    this._renderer.setProperty(
      this._elementRef.nativeElement,
      'checked',
      value
    );
  }

  registerOnChange(fn: (_: any) => {}): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => {}): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this._renderer.setProperty(
      this._elementRef.nativeElement,
      'disabled',
      isDisabled
    );
  }

  hasSuccess(): boolean {
    // return false;
    // //console.log("check1", this.input.invalid);

    return this.input.valid && (this.input.dirty || this.input.touched);
  }

  hasError(): boolean {
    return this.input.invalid && (this.input.dirty || this.input.touched);
  }
}
