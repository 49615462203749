import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  AfterViewInit,
  AfterContentInit,
  OnDestroy,
  ChangeDetectorRef,
  Output,
  EventEmitter,
} from '@angular/core';

//import { DualListbox} from 'dual-listbox';
//import { DualListbox } from '../../../../assets/js/components/dual-listbox';
@Component({
  selector: 'kt-duallistbox',
  templateUrl: './dual-listbox.component.html',
  styleUrls: ['./dual-listbox.component.scss'],
})
export class DualListboxComponent
  implements OnInit, AfterViewInit, AfterContentInit
{
  @ViewChild('selectId', { static: true }) el: ElementRef;

  @Input() id: string = 'select';

  @Input() class: string = 'dual-listbox';
  @Input() from: string = 'from';
  @Input() to: string = 'to';
  @Input() items: any[] = [];
  @Output() dualEmit: EventEmitter<any> = new EventEmitter();
  dualListbox: any;
  constructor(private ref: ChangeDetectorRef) {}
  ngAfterViewInit() {}
  ngOnInit() {
    // this.init();
  }
  ngAfterContentInit() {}

  init() {
    setTimeout(() => {
      this.ref.detectChanges();
      this.dualListbox = null;
      let select = document.querySelector(`select#${this.id}`);
      let c = this;

      this.dualListbox = new DualListbox(select, {
        addEvent: function (value) {
          // Should use the event listeners
          //   //console.log("adicionado", value);
          c.dualEmit.emit({
            type: 'add',
            id: value,
          });
        },
        removeEvent: function (value) {
          // Should use the event listeners
          //  //console.log("removido", value);
          c.dualEmit.emit({
            type: 'rem',
            id: value,
          });
        },
        availableTitle: this.from,
        selectedTitle: this.to,
        addButtonText: '<i class="flaticon2-next"></i>',
        removeButtonText: '<i class="flaticon2-back"></i>',
        addAllButtonText: '<i class="flaticon2-fast-next"></i>',
        removeAllButtonText: '<i class="flaticon2-fast-back"></i>',
        options: this.items,
      });
      this.dualListbox.search.classList.add('dual-listbox');
      this.dualListbox.addEventListener('added', function (event) {
        // //console.log("===", event);
        // //console.log("element added" , event.addedElement);
      });
      this.dualListbox.addEventListener('removed', function (event) {
        //  //console.log(event);
        //   //console.log(event.removedElement);
      });

      ////console.log("COMPONENTE", dualListbox);
    }, 0);
  }
  redraw(items) {
    this.items = items;
    this.dualListbox.selected = [];
    this.dualListbox.available = [];
    this.dualListbox.options = items;
    this.dualListbox._splitOptions(items);
    this.dualListbox.redraw();
  }
}
