import {
  Input,
  Component,
  Output,
  EventEmitter,
  OnInit,
  AfterViewChecked,
  ElementRef,
  OnChanges,
} from '@angular/core';
import { delay } from 'lodash';
import { GenericService } from '../../../core/services/generic.service';
// import { Tab, Longtab, Service } from './app.service';

@Component({
  selector: 'kt-scroll-tabs',
  templateUrl: './scroll-tabs.component.html',
  styleUrls: ['./scroll-tabs.component.scss'],
})
export class ScrollTabsComponent implements OnInit, OnChanges {
  @Input() tabs: Array<any> = [{ content: '1' }];

  @Input() grid: any = [];

  @Input() dataSource: any = [];

  @Output() tabEmit: EventEmitter<any> = new EventEmitter();

  @Output() gridEmit: EventEmitter<any> = new EventEmitter();

  tabContent: string;

  constructor(private service: GenericService, private elem: ElementRef) {}

  ngOnInit(): void {}

  ngOnChanges() {
    //	//console.log("Scroll Tabs>>", this.tabs);
    if (this.tabs) this.resultTabEmit(this.tabs[0]); // uraquitan
    /* for (const iterator of this.tabs) {
				this.resultTabEmit(this.tabs[iterator.id]);

		} */
  }

  selectedIndex = '0';

  currentSection = 'section0';

  onSectionChange(sectionId: string) {
    console.clear();
    //console.log('MUDOUUUU', sectionId);
    this.currentSection = sectionId;
    const getId = sectionId.split('section')[1];
    this.selectedIndex = getId;
    /* var getId = sectionId.split("section")[1];
    const tabdetail = this.tabs.find((x) => x.id === +getId);
    this.resultTabEmit(tabdetail); */
  }

  scrollTo(tabdetail) {
    ////console.log("MUDOUUU 222", tabdetail)
    // //console.log("datas", tabdetail);
    this.selectedIndex = tabdetail.id;
    this.currentSection = `section${tabdetail.id}`;
    //	document.querySelector("#section" + tabdetail.id).scrollIntoView();
    this.resultTabEmit({
      id: tabdetail.id,
      content: [],
      data: this.tabs[tabdetail.id],
    });
  }

  resultTabEmit(tabdetail) {
    //console.log('mudouuuu 3333', tabdetail);
    // if(!tabdetail) return;
    this.tabEmit.emit({
      data: tabdetail.content,
      content: this.tabs[tabdetail.id],
      id: tabdetail.id,
    });
  }

  selectTab(e) {
    this.scrollTo({ id: e.itemData.id });
  }

  onContentReady(e) {
    const elements = this.elem.nativeElement.querySelectorAll('.dx-tab');
    let maxWidth = 0;
    for (let i = 0; i < elements.length; i++) {
      maxWidth =
        maxWidth > elements[i].clientWidth ? maxWidth : elements[i].clientWidth;
    }
    elements.forEach((element) => {
      element.style.setProperty('width', `${maxWidth}px`);
    });
  }
}
