<div class="panel panel-default">
  <div class="panel-heading btn-success" role="tab" id="headingOne">
    <h1 class="panel-title">
      <a
        role="button"
        data-toggle="collapse"
        href="#collapse{{ id_collapse }}"
        aria-expanded="true"
        aria-controls="#collapse1"
      >
        <i class="short-full ki ki-arrow-down icon-nm"></i>
        {{ title }}
      </a>
    </h1>
  </div>
  <div
    id="collapse{{ id_collapse }}"
    class="panel-collapse collapse"
    role="tabpanel"
    aria-labelledby="headingOne"
  >
    <div class="panel-body">
      <!-- ---------------------------------- -->

      <ng-content></ng-content>
    </div>
    <!--</ng-container> -->
  </div>
</div>
