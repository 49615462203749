<!--begin::Subheader-->
<div class="subheader py-2 py-lg-6" [ngClass]="subheaderCSSClasses" id="kt_subheader">
  <div [ngClass]="subheaderContainerCSSClasses"
    class="d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
    <!--begin::Info-->
    <div class="d-flex align-items-center flex-wrap mr-1">
      <ng-container *ngIf="subheaderMobileToggle">
        <!--begin::Mobile Toggle-->
        <button class="burger-icon burger-icon-left mr-4 d-inline-block d-lg-none" id="kt_subheader_mobile_toggle">
          <span></span>
        </button>
        <!--end::Mobile Toggle-->
      </ng-container>

      <!--begin::Page Heading-->
      <div class="d-flex align-items-baseline flex-wrap mr-5">
        <!--begin::Page Title-->
        <ng-container *ngIf="title$ | async as _title">
          <h5 class="text-dark font-weight-bold my-1 mr-5">
            {{ _title }}
            <ng-container *ngIf="subheaderDisplayDesc">
              <ng-container *ngIf="description$ | async as _description">
                <small>{{ _description }}</small>
              </ng-container>
            </ng-container>
          </h5>
        </ng-container>
        <!--end::Page Title-->

        <!--begin::Breadcrumb-->
        <ul class="breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold p-0 my-2 font-size-sm">
          <li class="breadcrumb-item" *ngFor="let bc of breadcrumbs" routerLinkActive="active">
            <a [routerLink]="bc.linkPath" class="text-muted">
              {{ bc.linkText }}
            </a>
          </li>
        </ul>
        <!--end::Breadcrumb-->
      </div>
      <!--end::Page Heading-->
    </div>
    <!--end::Info-->

    <!--begin::Toolbar-->
    <!---->
    <div class="d-flex align-items-center">
      <ng-container *ngIf="subheaderDisplayDaterangepicker">




        <a class="btn btn-light-primary btn-sm font-weight-bold mr-2 cursor-pointer" id="kt_dashboard_daterangepicker"
          data-toggle="tooltip" title="Select dashboard daterange" data-placement="left">
          <span class="opacity-60 font-weight-bold mr-2" id="kt_dashboard_daterangepicker_title">Hello</span>
          <span class="font-weight-bold" id="kt_dashboard_daterangepicker_date">{{name}}</span>
        </a>

        <a class="cursor-pointer" (click)="openNotificationsPopup()">
        <span class="svg-icon svg-icon-xl svg-icon-primary"><!--begin::Svg Icon | path:/metronic/theme/html/demo1/dist/assets/media/svg/icons/Code/Compiling.svg--><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
          <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <rect x="0" y="0" width="24" height="24"></rect>
              <path d="M2.56066017,10.6819805 L4.68198052,8.56066017 C5.26776695,7.97487373 6.21751442,7.97487373 6.80330086,8.56066017 L8.9246212,10.6819805 C9.51040764,11.267767 9.51040764,12.2175144 8.9246212,12.8033009 L6.80330086,14.9246212 C6.21751442,15.5104076 5.26776695,15.5104076 4.68198052,14.9246212 L2.56066017,12.8033009 C1.97487373,12.2175144 1.97487373,11.267767 2.56066017,10.6819805 Z M14.5606602,10.6819805 L16.6819805,8.56066017 C17.267767,7.97487373 18.2175144,7.97487373 18.8033009,8.56066017 L20.9246212,10.6819805 C21.5104076,11.267767 21.5104076,12.2175144 20.9246212,12.8033009 L18.8033009,14.9246212 C18.2175144,15.5104076 17.267767,15.5104076 16.6819805,14.9246212 L14.5606602,12.8033009 C13.9748737,12.2175144 13.9748737,11.267767 14.5606602,10.6819805 Z" fill="#000000" opacity="0.3"></path>
              <path d="M8.56066017,16.6819805 L10.6819805,14.5606602 C11.267767,13.9748737 12.2175144,13.9748737 12.8033009,14.5606602 L14.9246212,16.6819805 C15.5104076,17.267767 15.5104076,18.2175144 14.9246212,18.8033009 L12.8033009,20.9246212 C12.2175144,21.5104076 11.267767,21.5104076 10.6819805,20.9246212 L8.56066017,18.8033009 C7.97487373,18.2175144 7.97487373,17.267767 8.56066017,16.6819805 Z M8.56066017,4.68198052 L10.6819805,2.56066017 C11.267767,1.97487373 12.2175144,1.97487373 12.8033009,2.56066017 L14.9246212,4.68198052 C15.5104076,5.26776695 15.5104076,6.21751442 14.9246212,6.80330086 L12.8033009,8.9246212 C12.2175144,9.51040764 11.267767,9.51040764 10.6819805,8.9246212 L8.56066017,6.80330086 C7.97487373,6.21751442 7.97487373,5.26776695 8.56066017,4.68198052 Z" fill="#000000"></path>
          </g>
      </svg><!--end::Svg Icon--></span>             
      <span
      *ngIf="totNotifications > 0"
      class="updates-count badge badge-primary"
      style="left: 13px; top: 5px; line-height: 9px;  font-size: 10px;  font-weight: bold;  border-radius: 10px;  text-align: center;  padding: 3px 5px;"
      >{{totNotifications}}
    </span> 
  </a>

    

    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </ng-container>



      <div class="dropdown dropdown-inline" data-toggle="tooltip" title="Leave" placement="bottom-right" ngbDropdown>
        <a class="btn btn-icon cursor-pointer" (click)="logout()">
          <i class="navi-icon flaticon-logout" style="color: #000">Leave</i>
        </a>
        
      </div>
    </div>

    <!--end::Toolbar-->
  </div>
</div>
<!--end::Subheader-->