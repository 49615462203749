import {
  Component,
  OnInit,
  Input,
  ContentChild,
  AfterContentInit,
  forwardRef,
  Renderer2,
  ElementRef,
} from '@angular/core';
import {
  FormControlName,
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';

@Component({
  selector: 'kt-input',
  host: {
    '(change)': 'onChange($event.target)',
    '(blur)': 'onTouched()',
  },
  templateUrl: './input.component.html',
  // styleUrls: ["./input.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => InputComponent),
    },
  ],
})
export class InputComponent
  implements OnInit, AfterContentInit, ControlValueAccessor
{
  @Input() type: string;

  @Input() idd: string;

  @Input() pH: string = '';

  @Input() colMD: string = '12';

  @Input() label: string;

  @Input() errorMessage: string;

  @Input() inputId: string;

  @Input() showTip: boolean = true;

  @Input() leftTxt1: string = '';

  @Input() leftTxt2: string = '';

  @Input() rightTxt: string = '';

  @Input() rightIcon: string = '';

  @Input() leftIcon: string = '';

  @Input() message: string = '';

  @Input() dateFormat: string = '';

  @Input() separator: string = 'false';

  @Input('value') _value = '';

  onChange: any = () => {};

  onTouched: any = () => {};

  isValidClass: string = '';

  input: any;

  // using contentChild, the Angular can inject references to the component.
  // To make it, we need the interface AfterContentInit
  @ContentChild(FormControlName, { read: false, static: true })
  control: FormControlName; // added to use Reactive Forms

  constructor(private _renderer: Renderer2, private _elementRef: ElementRef) {}

  registerOnChange(fn) {
    this.onChange = fn;
  }

  get value() {
    return this._value;
  }

  set value(val) {
    this._value = val;
    this.onChange(val);
    this.onTouched();
  }

  writeValue(value) {
    if (value) {
      this.value = value;
    }
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this._renderer.setProperty(
      this._elementRef.nativeElement,
      'disabled',
      isDisabled
    );
  }

  ngOnInit() {}

  ngAfterContentInit() {
    this.input = this.control;
    //	//console.log("this control", this.control);
    if (this.input === undefined) {
      throw new Error(
        'this componenet need to be used with a ngModel or FormControlName Directive'
      );
    }
  }
  /*
	private markFormGroupTouched(formGroup: FormGroup) {
		(<any>Object).values(formGroup.controls).forEach(control => {
			control.markAsTouched();

			if (control.controls) {
				this.markFormGroupTouched(control);
			}
		});
	} */

  hasSuccess(): boolean {
    // return false;
    // //console.log("check1", this.input.invalid);

    const ok = this.input.valid && (this.input.dirty || this.input.touched);
    if (ok) {
      this.isValidClass = 'is-valid';
    }
    return false;
  }

  hasError(): boolean {
    const error =
      this.input.invalid && (this.input.dirty || this.input.touched);
    if (error) {
      this.isValidClass = 'is-invalid';
    }
    return error;
  }

  hasLeftTxt1() {
    return this.leftTxt1.length > 0;
  }

  hasLeftTxt2() {
    return this.leftTxt2.length > 0;
  }

  hasRightTxt() {
    return this.rightTxt.length > 0;
  }

  hasLeftIcon() {
    return this.leftIcon.length > 0;
  }

  hasRightIcon() {
    return this.rightIcon.length > 0;
  }

  hasSeparator() {
    return this.separator !== 'false';
  }
}
