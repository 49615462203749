import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation,
} from '@angular/core';
import {
  HeatMapComponent,
  ILegendRenderEventArgs,
  ISelectedEventArgs,
  ITooltipEventArgs,
} from '@syncfusion/ej2-angular-heatmap';
import html2canvas from 'html2canvas';

import {
  HeatMap,
  Legend,
  Tooltip,
  Adaptor,
  ILoadedEventArgs,
  SelectedCellDetails,
  HeatMapTheme,
} from '@syncfusion/ej2-angular-heatmap';

import { MatDialog } from '@angular/material/dialog';
import { HeatmapSettings } from './settings/heatmap-settings.model'; // Adjust the import path
import { HeatmapSettingsModalComponent } from './settings/heatmap-settings-modal.component';
import { ExcelService } from './excel.service';
import { CheckBox } from '@syncfusion/ej2-buttons';
HeatMap.Inject(Tooltip, Legend, Adaptor);

//https://ej2.syncfusion.com/angular/demos/?utm_source=npm&utm_medium=listing&utm_campaign=angular-heatmap-npm#/material/heatmap-chart/cell-selection

@Component({
  selector: 'kt-heatmap',
  templateUrl: './heatmap.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./heatmap.component.scss'],
})
export class HeatmapComponent implements OnChanges, AfterViewInit {
  heatmapSettings: HeatmapSettings;

  @ViewChild('heatmapContainer', { static: false })
  heatmapContainer!: ElementRef;
  @ViewChild('heatmap', { static: false }) heatmap!: HeatMapComponent;

  @Input() title: string = '';
  @Input() dataSource: any[][] = [];
  titleSettings: Object = {};
  selectedLabels: string[] = [];
  gridStyle: number = 0.1;
  @Input() xAxis: {isInversed?:boolean, labels: Object[] } = { labels: [] };
  @Input() yAxis: {isInversed?:boolean, labels: Object[] } = { labels: [] };
  @Input() min: number = 0;
  @Input() cut: number = 0;
  @Input() max: number = 0;
  @Input() height: string = '400px';
  @Input() legendHeight: string = '350px';
  @Input() startColor: string = '#873B3B';
  @Input() middleColor: string = '#FFFF00';
  @Input() endColor: string = '#31893F';
  @Input() fColor: string = '#000000';
  @Input() allowSelection : boolean = false;
  isSelected: boolean = false;
  yAxisAux: any;//{ labels: Object[] } = { labels: [] };
  yAxisData: any[];
  dataSourceAux: Object[] = [];
  renderingMode: string = 'String';
  public isHeatmapRendered = false;
  itemsPerPage: number; // Number of items to display per page
  currentPage: number = 0; // Current page index
  totalPages: any;
  public paletteSettings: any;
  constructor(
    private elementRef: ElementRef,
    private cdr: ChangeDetectorRef,
    private dialog: MatDialog,
    private excelService: ExcelService
  ) {
    this.updatePaletteSettings();
  }
  
  ngAfterViewInit(): void {
    
    let xlistObj: CheckBox = new CheckBox({
      label: 'Reverse X-Axis', checked: false,
      change: () => {
          this.heatmap.xAxis.isInversed = xlistObj.checked;
      }
  });
  xlistObj.appendTo('#XOpposedPosition');
  let ylistObj: CheckBox = new CheckBox({
      label: 'Reverse Y-Axis', checked: false,
      change: () => {
          this.heatmap.yAxis.isInversed = ylistObj.checked;
      }
  });
  ylistObj.appendTo('#YOpposedPosition');
  }
  public load(args: ILoadedEventArgs): void {
    let selectedTheme: string = location.hash.split('/')[1];
    selectedTheme = selectedTheme ? selectedTheme : 'Material';
    args.heatmap.theme = <HeatMapTheme>(selectedTheme.charAt(0).toUpperCase() + selectedTheme.slice(1)).replace(/-dark/i, "Dark");
};
  public tooltipRender(args: ITooltipEventArgs | any): void {
    const v = args.value; //.toFixed(3);
    const x = args.xLabel;
    const y = args.yLabel;
    let xPos = this.xAxis.labels.indexOf(x);
    let yPos = this.yAxis.labels.indexOf(y);
    //let scoreL = this.xAxis.labels.indexOf('Score');
    const scoreL = this.xAxis.labels.findIndex(label => label.toString().toLowerCase() === 'score');
    const outcomesL = this.xAxis.labels.findIndex(label => label.toString().toLowerCase() === 'outcome');
    //const val = this.dataSource[yPos][xPos];
    const score = this.dataSource[scoreL][yPos];

    let outcomes = 0;
    if (outcomesL >=0) outcomes = this.dataSource[outcomesL][yPos];
   // console.log(scoreL, xPos, outcomesL,  all, score, outcomes);
     let type = '';
     const cutoff = this.cut;
     if(scoreL > 0 && outcomesL >=0) {
    
    type = 'True Positive';
    if(score >= cutoff) {
      if(outcomes < cutoff) type = 'False Positive';
    }
    else if(score < cutoff) {
      if(outcomes < cutoff) type = 'True Negative';
      else type = 'False Negative';
    }
  } else if(scoreL > 0 && outcomesL < 0) {
    type = 'Negative';
    if(score >= cutoff) {
       type = 'Positive';
    }
  }
    
/*
    let type = ''; 
    if(args.xLabel.toUpperCase() === 'OUTCOMES') {
      type = '<b>Negative</b> <br>';
      if (Number(this.cut) <= Number(v)) {
        type = '<b>Positive</b> <br>';
      }
    }
    else if (args.xLabel.toUpperCase() === 'SCORE') {
       type = '<b>Negative</b> <br>';
       if (Number(this.cut) <= Number(v)) {
         type = '<b>Positive</b><br>';
       }
    }
     */
    args.content = [
      `<b>${type}</b><br/>` +
        '<b>Sample:</b> ' +
        args.yLabel +
        '<br><b>Biomarker:</b>' +
        args.xLabel +
        '<br><b>Value:</b> ' +
        v +
        '<br><b>Cutoff:</b>' +
        this.cut.toFixed(3),
    ];
  }

  ngOnChanges() {
    this.updatePaletteSettings();
    this.cellSettings();
    if (this.selectedLabels.length === 0) {
      this.yAxisAux = this.yAxis;
      this.dataSourceAux = this.dataSource.map((row) =>
        row.map((value) => parseFloat(value).toFixed(1))
      );

      const aux = [];
      for (let i = this.yAxis?.labels?.length - 1; i >= 0; i--) {
        //console.log(this.yAxis.labels[i]);
        aux.push(`${this.yAxis.labels[i]}`);
      }
      this.yAxisData = aux; //this.yAxis.labels.reverse();
      //console.log(this.yAxisData[0], this.yAxis.labels[0])
    }
  }

  legendSettings() {
    return {
      position: 'Top',
      showLabel: true,
      segmentCount: 3,
      width: '100%',
      height: '80px', //this.legendHeight,
    };
  }

  public cellSettings() {
    return {
      border: {
        width: this.gridStyle,
      },

      textStyle: {
        color: this.fColor,
      },
      format: '{value}',
      //format: '{ ' + this.formatValue.toString() + '(value) }'
    };
  }
  public formatValue(value: number): string {
    return value.toFixed(0);
  }

  public legendRender(args: ILegendRenderEventArgs | any): void {
    if (Number(args.text) === Number(this.min.toFixed(3).toString())) {
      args.text = args.text.replace(/,/, '');
      args.text = args.text + '(Negative)';
    } else if (args.text === this.cut.toFixed(3).toString()) {
      args.text = args.text.replace(/,/, '');
      args.text = args.text + '(Cutoff)';
    } else if (args.text === this.max.toFixed(3).toString()) {
      args.text = args.text.replace(/,/, '');
      args.text = args.text + '(Positive)';
    } else {
      // Handle other cases if needed
    }
  }
  private updatePaletteSettings() {
    this.paletteSettings = {
      palette: [
        { value: this.min, color: this.startColor },
        { value: this.cut, color: this.middleColor },
        { value: this.max, color: this.endColor },
        /*{ value: 0, color: '#C06C84' },
      { value: 0.5, color: '#6C5B7B' },
      { value: 1, color: '#355C7D' },*/
      ],
      type: 'Gradient',
    };
  }
  settitleSettings() {
    return (this.titleSettings = {
      text: this.title,
      textStyle: {
        size: '15px',
        fontWeight: '500',
        fontStyle: 'Normal',
      },
    });
  }

  exportToPng() {
    // Get the heatmap's container element
    const container = this.heatmapContainer.nativeElement;

    // Use html2canvas to convert the heatmap container to a PNG image
    html2canvas(container).then((canvas) => {
      // Create a temporary link to download the PNG image
      const dataUrl = canvas.toDataURL('image/png');
      const a = document.createElement('a');
      a.href = dataUrl;
      a.download = 'heatmap.png';
      a.click();
    });
  }

  // =============================
  // Pagination settings

  // Method to get the current page data
  getCurrentPageData(): Object[] {
    if (Object.prototype.hasOwnProperty.call(this.xAxis, 'labels')) {
      const startIndex = this.currentPage * this.itemsPerPage;
      const items = this.xAxis.labels;
      const endIndex = Math.min(startIndex + this.itemsPerPage, items.length);
      return this.dataSource.slice(startIndex, endIndex);
    } else {
      return [];
    }
  }

  // Method to get the current page xAxis
  /*get currentXAxis(): Object[] {
    console.log("AXIII", this.xAxis)
    const startIndex = this.currentPage * this.itemsPerPage;
    const endIndex = Math.min(startIndex + this.itemsPerPage, this.xAxis.length);
    return this.xAxis.slice(startIndex, endIndex);
  }*/

  getCurrentXAxis() {
    if (Object.prototype.hasOwnProperty.call(this.xAxis, 'labels')) {
      this.totalPages = Math.ceil(this.xAxis.labels.length / this.itemsPerPage); // Total number of pages

      const startIndex = this.currentPage * this.itemsPerPage;
      const endIndex = Math.min(
        startIndex + this.itemsPerPage,
        this.xAxis.labels.length
      );
      const ret = this.xAxis.labels.slice(startIndex, endIndex);
      return { labels: ret };
    } else {
      return []; // Return an empty array if xAxis is empty
    }
  }

  // Method to navigate to the previous page
  prevPage() {
    if (this.currentPage > 0) {
      this.currentPage--;
    }
  }

  // Method to navigate to the next page
  nextPage() {
    this.totalPages = Math.ceil(this.xAxis.labels.length / this.itemsPerPage); // Total number of pages
    if (this.currentPage < this.totalPages - 1) {
      this.currentPage++;
    }
  }
  calculateHeatmapHeight(): string {
    if (Object.prototype.hasOwnProperty.call(this.xAxis, 'labels')) {
      const rowHeight = 45; // Adjust this value based on your design
      const padding = 45; // Add padding for better visualization
      const numRows = this.xAxis.labels.length;

      return numRows * rowHeight + padding + 'px';
    } else return '400px';
  }

  toggleLabel(label: string) {
    if (this.selectedLabels.includes(label)) {
      this.selectedLabels = this.selectedLabels.filter((l) => l !== label);
    } else {
      this.selectedLabels.push(label);
    }
  }

  filterData() {
    const selectedIndices = this.selectedLabels.map((label) =>
      this.yAxis.labels.indexOf(label)
    );
    const auxDatasource = [];
    for (let i = 0; i < this.dataSource.length; i++) {
      const auxDt = this.dataSource[i].filter((data, index) =>
        selectedIndices.includes(index)
      );
      auxDatasource.push(auxDt);
    }

    this.yAxisAux = {
      labels: this.yAxis.labels.filter((data, index) =>
        selectedIndices.includes(index)
      ),
    };
    this.dataSourceAux = auxDatasource;
    if (this.selectedLabels.length === 0) {
      this.yAxisAux = this.yAxis;
      this.dataSourceAux = this.dataSource;
    }
    let height = 300;
    const tot = this.yAxisAux.labels.length;
    height = (4 + tot) * 45;
    if (height < 300) height = 300 + tot;
    this.height = height.toString() + 'px';
    //console.log("height",this.yAxisAux, this.yAxisAux.labels.length * 45, this.height)
    this.startColor = '#000000';
    this.cdr.detectChanges();
    // this.filteredData.emit(filteredData);
  }
  openHeatmapSettingsModal(): void {
    const t = this;
    const dialogRef = this.dialog.open(HeatmapSettingsModalComponent, {
      width: '400px', // Adjust the width as needed
      data: this.heatmapSettings,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        // Apply the updated settings
        t.heatmapSettings = result;
        t.gridStyle = t.heatmapSettings.gridFormat;
        t.startColor = t.heatmapSettings.colorScheme.startColor;
        t.middleColor = t.heatmapSettings.colorScheme.middleColor;
        t.endColor = t.heatmapSettings.colorScheme.endColor;
        t.fColor = t.heatmapSettings.colorScheme.fontColor;
        const rot = t.heatmapSettings.xAxis.labelRotation;
        const pos = t.heatmapSettings.xAxis.opposedPosition;
        
        this.heatmap.xAxis.labelRotation = rot;
        this.heatmap.xAxis.opposedPosition = pos;

        t.updatePaletteSettings();
        t.cellSettings();
        setTimeout(() => {
          t.cdr.checkNoChanges();
        }, 300);

        // Call a method to apply the settings to your heatmap component
        this.applyHeatmapSettings();
      }
    });
  }
  reverseX(e) {
    console.log(e)
      this.yAxisAux.isInversed =e;
      this.yAxis.isInversed =e;
      this.cdr.detectChanges();
      console.log(this.yAxisAux)
  }
  applyHeatmapSettings(): void {
    // Apply the settings to your heatmap component
    // ...
  }

  exportToExcel(): void {
    let transposedDataSource: any;
    if (this.selectedLabels.length > 0) {
      transposedDataSource = this.dataSourceAux.map((_, colIndex) =>
        this.dataSourceAux.map((row) => row[colIndex])
      );
    } else {
      transposedDataSource = this.heatmap.dataSource[0].map((_, colIndex) =>
        this.dataSource.map((row) => row[colIndex])
      );
    }
    const dataForExcel = [
      [null, ...this.xAxis.labels], // First row with column headers
      ...this.yAxisAux.labels.map((yValue, yIndex) => [
        yValue,
        ...transposedDataSource[yIndex],
      ]), // Rows with yAxis and transposed dataSource values
    ];

    this.excelService.exportToExcel(dataForExcel, 'Heatmap', 'heatmap_data');
  }

  public cellSelected(args: ISelectedEventArgs): void {
    this.isSelected = true;
    let data: SelectedCellDetails[]  = args.data
        let length : number = data.length;
        let xAxis : string[] = [];
        let flag : boolean[] = []
        let series: any = [];
         for (let i: number = 0; i < length; i++) {
             if (xAxis.indexOf(data[i].xLabel) === -1) {
                 xAxis.push(data[i].xLabel);
                 flag.push(false);
             }
         }
         for (var i: number = 0; i < length; i++) {
             var index = xAxis.indexOf(data[i].xLabel);
             if (!flag[index]) {
                 flag[index] = true;
                 var column: any = {};
                 column.type = 'Column';
                 column.xName = 'x';
                 column.yName = 'y';
                 column.width = 2;
                 column.name = data[i].xLabel;
                 column.marker = { dataLabel: { visible: false } };
                 column.dataSource = [];
                 var columnData: any = {};
                 columnData.x = data[i].yLabel;
                 columnData.y = data[i].value;
                 column.dataSource.push(columnData);
                 series.push(column);
             }
             else {
                 var columnData: any = {};
                 columnData.x = data[i].yLabel;
                 columnData.y = data[i].value;
                 series[index].dataSource.push(columnData);
             }
             console.log(data)
         }
        // this.chart.series = series;
        // this.chart.refresh();
}
uncheck(){
  this.isSelected = false;
  this.heatmap.clearSelection();
}
}
