import { Component, OnInit, Input, ChangeDetectorRef, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { LayoutService } from '../../../../core';
import { SubheaderService } from '../_services/subheader.service';
import { BreadcrumbItemModel } from '../_models/breadcrumb-item.model';
import { KeycloakService } from 'keycloak-angular';
import { KeycloakProfile } from 'keycloak-js';
import { MatDialog } from '@angular/material/dialog';
import { NotificationsDropdownInnerComponent } from '../../extras/dropdown-inner/notifications-dropdown-inner/notifications-dropdown-inner.component';

@Component({
  selector: 'app-subheader1',
  templateUrl: './subheader1.component.html',
  styleUrls: ['./subheader1.component.scss'],
  providers:[MatDialog]
})
export class Subheader1Component implements OnInit {

  subheaderCSSClasses = '';

  subheaderContainerCSSClasses = '';

  subheaderMobileToggle = false;

  subheaderDisplayDesc = false;

  subheaderDisplayDaterangepicker = false;

  title$: Observable<string>;

  breadcrumbs$: Observable<BreadcrumbItemModel[]>;

  breadcrumbs: BreadcrumbItemModel[] = [];

  description$: Observable<string>;

  @Input() title: string;
  name: string = '';
  lp: KeycloakProfile;
  totNotifications: number = 0;
  constructor(
    private layout: LayoutService,
    private subheader: SubheaderService,
    private cdr: ChangeDetectorRef,
    private auth: KeycloakService,
    public dialog: MatDialog
  ) {
    this.title$ = this.subheader.titleSubject.asObservable();
  }
  async openNotificationsPopup() {
    if(this.totNotifications > 0) {
       await this.subheader.setReadNotifications();
       await this.getTotNot();

    }
    const dialogRef = this.dialog.open(NotificationsDropdownInnerComponent, {
      width: '400px',
      position: { top: '60px', right: '0px' }
    });
  }
  loadProfile(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      if (this.auth.isLoggedIn()) {
        this.auth
          .loadUserProfile()
          .then((data) => resolve(data))
          .catch((error) => console.log('AUTH ERROR:', error));
      } else {
        //redirect to login
      }
    });
  }
  async ngOnInit() {
    this.lp = await this.loadProfile();
    await this.getTotNot();
    this.name = this.lp.firstName;
    this.title$ = this.subheader.titleSubject.asObservable();
    this.breadcrumbs$ = this.subheader.breadCrumbsSubject.asObservable();
    this.description$ = this.subheader.descriptionSubject.asObservable();
    this.subheaderCSSClasses = this.layout.getStringCSSClasses('subheader');
    this.subheaderContainerCSSClasses = this.layout.getStringCSSClasses(
      'subheader_container'
    );
    this.subheaderMobileToggle = this.layout.getProp('subheader.mobileToggle');
    this.subheaderDisplayDesc = this.layout.getProp('subheader.displayDesc');
    this.subheaderDisplayDaterangepicker = this.layout.getProp(
      'subheader.displayDaterangepicker'
    );
    this.breadcrumbs$.subscribe((res) => {
      //console.log("AOQUIII", res);
      this.breadcrumbs = res;
      this.cdr.detectChanges();
    });
  }
  logout() {
    this.auth.logout();
  }
  async getTotNot() {
    const rTot:any = await this.subheader.totNotifications();
    
    if(rTot.status === 200) {
      const tot = rTot.data.result;
      if (tot.length > 0){
        this.totNotifications = tot[0].total;
       // console.log("tottt", tot)
        
      }
      else this.totNotifications = 0;
      this.cdr.detectChanges();
    }
  }
}
