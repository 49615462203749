import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  AfterViewInit,
  OnDestroy,
} from '@angular/core';
import KTWizard from '../../../../assets/js/components/wizard';
import { KTUtil } from '../../../../assets/js/components/util';

@Component({
  selector: 'kt-wizard2',
  templateUrl: './wizard2.component.html',
  styleUrls: ['./wizard2.component.scss'],
})
export class Wizard2Component implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('wizard', { static: true }) el: ElementRef;

  @Input() title: string;

  @Input() menu: any[] = [];

  @Input() previous: string = 'Back';

  @Input() next: string = 'Next';

  @Input() submit: string = 'Submit';

  submitted = false;

  wizard: any;
  /*
  menu: any[] = [
    {
      title: 'Account Settings',
      subtitle: 'Setup your Account Details',
      type: 'step',
      state: 'current',
      icon: 'flaticon2-browser-2',
      content_title: 'Enter your Account Details',
      content_state: 'current',
      component: 'teste1',
    },
    {
      title: 'Setup Oocations',
      subtitle: 'Choose your Location',
      type: 'step',
      icon: 'flaticon2-browser-2',
      content_title: 'title2',
      component: 'teste2',
    },
    {
      title: 'Support Channels',
      subtitle: 'Add Your Support ASgents',
      type: 'step',
      state: 'pending',
      icon: 'flaticon2-browser-2',
      content_title: 'title3',
      component: 'teste3',
    },
    {
      title: 'Set Your Delivery Address',
      subtitle: 'Address, Street, City, State',
      type: 'step',
      state: 'pending',
      icon: 'flaticon2-browser-2',
      content_title: 'title4',
      component: 'teste4',
    },
    {
      title: 'Make Payment',
      subtitle: 'Use Credit or Debit Cards',
      type: 'step',
      state: 'pending',
      icon: 'flaticon2-browser-2',
      content_title: 'title5',
      component: 'teste5',
    },
    {
      title: 'Completed!',
      subtitle: 'Review and Submit',
      type: 'step',
      state: 'pending',
      icon: 'flaticon2-browser-2',
      content_title: 'title6',
      component: 'teste6',
    },
  ]; */

  ngOnInit() {}

  ngAfterViewInit(): void {
    // Initialize form wizard
    this.wizard = new KTWizard(this.el.nativeElement, {
      startStep: 1,
    });

    // Validation before going to next page
    this.wizard.on('beforeNext', (wizardObj) => {
      //console.log('checkkkkkk AQUIIIIIII NEXT BUTTON', wizardObj);
      // https://angular.io/guide/forms
      // https://angular.io/guide/form-validation
      // validate the form and use below function to stop the wizard's step
      wizardObj.stop();
    });

    // Change event
    this.wizard.on('change', () => {
      setTimeout(() => {
        KTUtil.scrollTop();
      }, 500);
    });
  }

  onSubmit() {
    this.submitted = true;
  }

  ngOnDestroy() {
    this.wizard = undefined;
  }
}
