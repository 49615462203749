import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as rasterizeHTML from 'rasterizehtml';

const PDF_EXTENSION = '.pdf';
@Injectable()
export class PdfService {
  constructor() {}

  public exportAsPdfFile(json: any[], pdfFileName: string): void {
    /* const doc = new jsPDF();
    for (let i = 0; i < json.length; i++) {
      const header = [];
      const bodyMain = [];
      for (let j = 0; j < json[i].length; j++) {
        const body = [];
        for (const key in json[i][j]) {
          if (!header.includes(key)) {
            header.push(key);
          }
          body.push(json[i][j][key]);
        }
        bodyMain.push(body);
      }
      
    }
    this.saveAsPdfFile(doc, pdfFileName);*/
  }

  private saveAsPdfFile(buffer: any, fileName: string): void {
    buffer.save(`${fileName}_export${PDF_EXTENSION}`);
  }

  public htmlToPdf(
    divId = '',
    content: any = '',
    pdfFileName: string = 'payroll',
    canvas: HTMLCanvasElement
  ): void {
    /*  const doc = new jsPDF('p', 'pt', 'letter');
    const specialElementHandlers = {
      '#editorw': function (element, renderer) {
        return true;
      },
    };

    // content = decodeURIComponent(escape(atob(content)));
    //	let auxDoc = new DOMParser().parseFromString(content, "text/xml");

    // //console.log("root", content.toString()); // => <a href="#">Link...

    // content = decodeURIComponent(escape(atob(content)));
    if (content !== '') {


      rasterizeHTML.drawHTML(content, canvas).then((elem) => {
        doc.fromHTML(canvas, 15, 15, {
          width: 190,
          elementHandlers: specialElementHandlers,
        });

        doc.save(pdfFileName + PDF_EXTENSION);
      });
    }*/
  }
}
