<dx-list class="dx-list-reorder-handle list-cursor-move" [items]="orders" [repaintChangesOnly]="true" keyExpr="id" style="height:500px">
  <dxo-item-dragging group="tasks" [data]="orders" [allowReordering]="true" [onDragStart]="onDragStart" [onAdd]="onAdd"
    [onRemove]="onRemove">
  </dxo-item-dragging>
</dx-list>

<div class="row mb-5 buttonSection">
  <div
    class="col btn-block text-center buttonRow"
    *ngFor="let buttonName of buttonNames"
  >
    <kt-button
      nameText="{{ buttonName.name }}"
      buttonType="{{ buttonName.id }}"
      (selectButton)="onButtonClick($event)"
    >
    </kt-button>
  </div>
</div>