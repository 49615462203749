import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError, delay } from 'rxjs/operators';
import { AxiosService } from 'src/app/_metronic/core/services/axios.service';
import { UserModel } from '../../../_models/user.model';
import { AuthModel } from '../../../_models/auth.model';
import { UsersTable } from '../../../../../_fake/fake-db/users.table';
import { environment } from '../../../../../../environments/environment';
//${environment.apiUrl}
import axios from 'axios';
const API_USERS_URL = `api/users`;

@Injectable({
  providedIn: 'root',
})
export class AuthHTTPService {
  // httpAxios: any;
  constructor(private injector: Injector, private http: HttpClient) {
    // this.httpAxios = this.injector.get<AxiosService>(AxiosService);
  }

  async login2(username: string, password: string) {
    const response = await axios.post(`${environment.apiUrl}/login`, {
      username,
      password,
    });
    // //console.log("RETORNO", response);
    if (!response) return;
    const { access_token, refresh_token, expires_in } = response.data.data;
    // //console.log(response.data.data);
    const auth = new AuthModel();

    auth.accessToken = access_token;
    auth.refreshToken = refresh_token;
    auth.expiresIn = expires_in;
    return auth;
  }
  async refresh(refreshToken: string) {
    const response = await axios.post(`${environment.apiUrl}/refresh`, {
      refreshToken,
    });
    // //console.log("RETORNO", response);
    if (!response) return;
    const { access_token, refresh_token, expires_in } = response.data.data;
    ////console.log(response.data.data);
    const auth = new AuthModel();

    auth.accessToken = access_token;
    auth.refreshToken = refresh_token;
    auth.expiresIn = expires_in;
    return auth;
  }
  // public methods
  login(email: string, password: string): Observable<any> {
    const notFoundError = new Error('Not Found');
    if (!email || !password) {
      return of(notFoundError);
    }
    // aqui

    //fim aqui
    return this.getAllUsers().pipe(
      map((result: UserModel[]) => {
        if (result.length <= 0) {
          return notFoundError;
        }

        const user = result.find(
          (u) =>
            u.email.toLowerCase() === email.toLowerCase() &&
            u.password === password
        );
        if (!user) {
          return notFoundError;
        }

        const auth = new AuthModel();

        auth.accessToken = user.accessToken;
        auth.refreshToken = user.refreshToken;
        auth.expiresIn = new Date(Date.now() + 100 * 24 * 60 * 60 * 1000);
        return auth;
      })
    );
  }

  createUser(user: UserModel): Observable<any> {
    user.roles = [2]; // Manager
    user.accessToken = `access-token-${Math.random()}`;
    user.refreshToken = `access-token-${Math.random()}`;
    user.expiresIn = new Date(Date.now() + 100 * 24 * 60 * 60 * 1000);
    user.pic = './assets/media/users/default.jpg';

    return this.http.post<UserModel>(API_USERS_URL, user);
  }

  forgotPassword(email: string): Observable<boolean> {
    return this.getAllUsers().pipe(
      map((result: UserModel[]) => {
        const user = result.find(
          (u) => u.email.toLowerCase() === email.toLowerCase()
        );
        return user !== undefined;
      })
    );
  }

  getUserByToken(token: string): Observable<UserModel> {
    const user = UsersTable.users.find((u) => u.accessToken === token);

    if (!user) {
      return of(undefined);
    }

    return of(user);
  }

  getAllUsers(): Observable<UserModel[]> {
    return this.http.get<UserModel[]>(API_USERS_URL);
  }
}
