import { KeycloakService } from "keycloak-angular";
import { environment } from "src/environments/environment";
export function initializeKeycloak(
  keycloak: KeycloakService
  ):() => Promise<boolean> {
    return () =>
      keycloak.init({
        config: environment.keycloak,
    
        initOptions: {
        onLoad: 'login-required',
        checkLoginIframe: false,
        checkLoginIframeInterval: 25,
       // onLoad: 'check-sso',
       /* silentCheckSsoRedirectUri:
          window.location.origin + '/assets/silent-check-sso.html',*/
      },
      });
}