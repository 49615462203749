<div class="row">
  <div class="card-body pt-12">
    <h3 class="card-title font-weight-bold text-dark">{{title}}</h3>
  </div>
</div>
<div class="row">
  <div *ngFor="let item of items" class="col-xl-{{col}}">
    <div class="card card-custom card-stretch gutter-b">

      <div class="align-items-end card-toolbar">
        <div class="row">
          <div class="col-md-10">
            <div class="card-header border-0 pt-5">
              <h3 class="card-title  flex-column">
                <span style="margin-top:-5px;"
                  class="card-label font-size-sm font-weight-bolder text-dark">{{item.title}}</span>
                <!--<span class="text-muted mt-3 font-weight-bold font-size-sm">Pending 10 tasks</span>-->
              </h3>
            </div>
          </div>
          <div class="col-md-2  align-items-end" style="text-align:right;">

            <i class="btn btn-primary btn-sm ki ki-plus" (click)="add(item.id, item.title)"
              style="text-align:right;  cursor: pointer; color: white;"></i>
            <!--<button class="btn btn-primary btn-sm font-weight-bolder dropdown-toggle text-light-75"
                (click)="add(item.id)"><i class="ki ki-plus font-size-sm"></i></button>-->

          </div>

        </div>
      </div>

      <div class="card-body pt-8" style="margin-top:-30px; width: 100%; height: 150px; overflow-y: scroll;">
        <div *ngFor="let it of item.list" class="d-flex flex-wrap  w-100">

          <!-- ==========================   -->
          <!--<div class="mb-6">-->
          <div class="d-flex align-items-center flex-grow-1">
            <div class=" symbol symbol-40 symbol-light-{{it.colorIcon}} mr-5">
              <span class="symbol-label">
                <span class="svg-icon svg-icon-sm svg-icon-{{it.colorIcon}}">
                  <i _ngcontent-wau-c121="" class="fa {{it.icon}} icon-3x  svg-icon text-{{it.colorIcon}}"></i>
                </span>
              </span>
            </div>
            <div class="d-flex flex-wrap align-items-center justify-content-between w-100">
              <div class="d-flex flex-column align-items-cente py-2 w-75">
                <span class="text-dark-75 font-weight-bold font-size-lg mb-1">{{it.title}}</span>
                <span class="text-muted font-weight-bold">{{it.subTitle}}</span>
              </div>
              <span class="">
                <span style="cursor: pointer" class="svg-icon svg-icon-xl text-danger">
                  <i _ngcontent-wau-c121="" (click)="del(it.id)"
                    class="fa fa-trash svg-icon-xl  svg-icon text-danger"></i>
                </span>

              </span>
            </div>
          </div>

          <!--</div>-->

          <!--
          <div class=" symbol symbol-40 symbol-light-{{it.colorIcon}} mr-5">
            <span class="symbol-label">
              <span class="svg-icon svg-icon-xl svg-icon-{{it.colorIcon}}">
                <i _ngcontent-wau-c121="" class="fa {{it.icon}} icon-3x  svg-icon text-{{it.colorIcon}}"></i>
              </span>
            </span>
          </div>
          <div class="d-flex flex-wrap justify-content-between">
            <div class="d-flex flex-column font-weight-bold">
              <a (click)="link(it)" class="text-dark text-hover-primary mb-1 font-size-lg"
                style="cursor: pointer">{{it.title}}</a>
              <span class="text-muted">{{it.subTitle}}</span>
            </div>
            <span style="text-align: right; background-color: green;"
              class="d-flex flex-column label label-lg label-light-primary label-inline font-weight-bold py-4">Approved</span>
          </div>
          -->
        </div>
      </div>
    </div>
  </div>
</div>