<!--begin::Quick Cart-->
<div id="kt_quick_cart" class="offcanvas p-10" [ngClass]="extrasCartOffcanvasDirectionCSSClass">
  <!--begin::Header-->
  <div class="offcanvas-header d-flex align-items-center justify-content-between pb-7">
    <h4 class="font-weight-bold m-0">
      Shopping Cart
    </h4>
    <a href="#" class="btn btn-xs btn-icon btn-light btn-hover-primary" id="kt_quick_cart_close">
      <i class="ki ki-close icon-xs text-muted"></i>
    </a>
  </div>
  <!--end::Header-->

  <!--begin::Content-->
  <div class="offcanvas-content">
    <!--begin::Wrapper-->
    <div class="offcanvas-wrapper mb-5 scroll-pull">
      <!--begin::Item-->
      <div class="d-flex align-items-center justify-content-between py-8">
        <div class="d-flex flex-column mr-2">
          <a href="#" class="font-weight-bold text-dark-75 font-size-lg text-hover-primary">
            iBlender
          </a>
          <span class="text-muted">
            The best kitchen gadget in 2020
          </span>
          <div class="d-flex align-items-center mt-2">
            <span class="font-weight-bold mr-1 text-dark-75 font-size-lg">$ 350</span>
            <span class="text-muted mr-1">for</span>
            <span class="font-weight-bold mr-2 text-dark-75 font-size-lg">5</span>
            <a href="#" class="btn btn-xs btn-light-success btn-icon mr-2"><i class="ki ki-minus icon-xs"></i></a>
            <a href="#" class="btn btn-xs btn-light-success btn-icon"><i class="ki ki-plus icon-xs"></i></a>
          </div>
        </div>
        <!--<a href="#" class="symbol symbol-70 flex-shrink-0">
          <img src="./assets/media/stock-600x400/img-1.jpg" title="" alt="" />
        </a>-->
      </div>
      <!--end::Item-->

      <!--begin::Separator-->
      <div class="separator separator-solid"></div>
      <!--end::Separator-->

      <!--begin::Item-->
      <div class="d-flex align-items-center justify-content-between py-8">
        <div class="d-flex flex-column mr-2">
          <a href="#" class="font-weight-bold text-dark-75 font-size-lg text-hover-primary">
            SmartCleaner
          </a>
          <span class="text-muted">
            Smart tool for cooking
          </span>
          <div class="d-flex align-items-center mt-2">
            <span class="font-weight-bold mr-1 text-dark-75 font-size-lg">$ 650</span>
            <span class="text-muted mr-1">for</span>
            <span class="font-weight-bold mr-2 text-dark-75 font-size-lg">4</span>
            <a href="#" class="btn btn-xs btn-light-success btn-icon mr-2"><i class="ki ki-minus icon-xs"></i></a>
            <a href="#" class="btn btn-xs btn-light-success btn-icon"><i class="ki ki-plus icon-xs"></i></a>
          </div>
        </div>
        <!--- <a href="#" class="symbol symbol-70 flex-shrink-0">
          <img src="./assets/media/stock-600x400/img-2.jpg" title="" alt="" />
        </a>-->
      </div>
      <!--end::Item-->

      <!--begin::Separator-->
      <div class="separator separator-solid"></div>
      <!--end::Separator-->

      <!--begin::Item-->
      <div class="d-flex align-items-center justify-content-between py-8">
        <div class="d-flex flex-column mr-2">
          <a href="#" class="font-weight-bold text-dark-75 font-size-lg text-hover-primary">
            CameraMax
          </a>
          <span class="text-muted">
            Professional camera for edge cutting shots
          </span>
          <div class="d-flex align-items-center mt-2">
            <span class="font-weight-bold mr-1 text-dark-75 font-size-lg">$ 150</span>
            <span class="text-muted mr-1">for</span>
            <span class="font-weight-bold mr-2 text-dark-75 font-size-lg">3</span>
            <a href="#" class="btn btn-xs btn-light-success btn-icon mr-2"><i class="ki ki-minus icon-xs"></i></a>
            <a href="#" class="btn btn-xs btn-light-success btn-icon"><i class="ki ki-plus icon-xs"></i></a>
          </div>
        </div>
        <!--<a href="#" class="symbol symbol-70 flex-shrink-0">
          <img src="./assets/media/stock-600x400/img-3.jpg" title="" alt="" />
        </a>-->
      </div>
      <!--end::Item-->

      <!--begin::Separator-->
      <div class="separator separator-solid"></div>
      <!--end::Separator-->

      <!--begin::Item-->
      <div class="d-flex align-items-center justify-content-between py-8">
        <div class="d-flex flex-column mr-2">
          <a href="#" class="font-weight-bold text-dark text-hover-primary">
            4D Printer
          </a>
          <span class="text-muted">
            Manufactoring unique objects
          </span>
          <div class="d-flex align-items-center mt-2">
            <span class="font-weight-bold mr-1 text-dark-75 font-size-lg">$ 1450</span>
            <span class="text-muted mr-1">for</span>
            <span class="font-weight-bold mr-2 text-dark-75 font-size-lg">7</span>
            <a href="#" class="btn btn-xs btn-light-success btn-icon mr-2"><i class="ki ki-minus icon-xs"></i></a>
            <a href="#" class="btn btn-xs btn-light-success btn-icon"><i class="ki ki-plus icon-xs"></i></a>
          </div>
        </div>
        <!--<a href="#" class="symbol symbol-70 flex-shrink-0">
          <img src="./assets/media/stock-600x400/img-4.jpg" title="" alt="" />
        </a>-->
      </div>
      <!--end::Item-->

      <!--begin::Separator-->
      <div class="separator separator-solid"></div>
      <!--end::Separator-->

      <!--begin::Item-->
      <div class="d-flex align-items-center justify-content-between py-8">
        <div class="d-flex flex-column mr-2">
          <a href="#" class="font-weight-bold text-dark text-hover-primary">
            MotionWire
          </a>
          <span class="text-muted">
            Perfect animation tool
          </span>
          <div class="d-flex align-items-center mt-2">
            <span class="font-weight-bold mr-1 text-dark-75 font-size-lg">$ 650</span>
            <span class="text-muted mr-1">for</span>
            <span class="font-weight-bold mr-2 text-dark-75 font-size-lg">7</span>
            <a href="#" class="btn btn-xs btn-light-success btn-icon mr-2"><i class="ki ki-minus icon-xs"></i></a>
            <a href="#" class="btn btn-xs btn-light-success btn-icon"><i class="ki ki-plus icon-xs"></i></a>
          </div>
        </div>
        <!--<a href="#" class="symbol symbol-70 flex-shrink-0">
          <img src="./assets/media/stock-600x400/img-8.jpg" title="" alt="" />
        </a>-->
      </div>
      <!--end::Item-->
    </div>
    <!--end::Wrapper-->

    <!--begin::Purchase-->
    <div class="offcanvas-footer">
      <div class="d-flex align-items-center justify-content-between mb-4">
        <span class="font-weight-bold text-muted font-size-sm mr-2">Total</span>
        <span class="font-weight-bolder text-dark-50 text-right">$1840.00</span>
      </div>
      <div class="d-flex align-items-center justify-content-between mb-7">
        <span class="font-weight-bold text-muted font-size-sm mr-2">Sub total</span>
        <span class="font-weight-bolder text-primary text-right">$5640.00</span>
      </div>
      <div class="text-right">
        <button type="button" class="btn btn-primary text-weight-bold">
          Place Order
        </button>
      </div>
    </div>
    <!--end::Purchase-->
  </div>
  <!--end::Content-->
</div>
<!--end::Quick Cart-->