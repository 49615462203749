<!--<div
	class="form-group has-feedback"
	[class.has-success]="hasSuccess()"
	[class.has-error]="hasError()"
>
	<label class="control-label sr-only" for="{{ inputId }}"
		><i class="fa fa-check"></i> {{ label }}</label
	>
	<ng-content></ng-content>

	<span class="help-block" *ngIf="showTip && hasSuccess()"
		><i class="fa fa-check"></i> Ok</span
	>
	<span class="help-block" *ngIf="showTip && hasError()"
		><i class="fa fa-remove"></i>{{ errorMessage }}</span
	>
</div>-->

<div class="form-group col-md-{{ colMD }}">
	<label>{{ label }}</label>
	<div class="input-group">
		<div class="input-group-append">
			<span [class.input-group-text]="hasLeftIcon()">
				<i class="{{ leftIcon }}" *ngIf="hasLeftIcon()"></i
			></span>
		</div>
		<div class="input-group-prepend">
			<span [class.input-group-text]="hasLeftTxt1()">{{ leftTxt1 }}</span>
			<span [class.input-group-text]="hasLeftTxt2()">{{ leftTxt2 }}</span>
		</div>
		<input
			type="{{ type }}"
			#input
			class="form-control {{ isValidClass }}"
			id="{{ idd }}"
			placeholder="{{ pH }}"
			aria-describedby="basic-addon1"
			autocomplete="false"
			[value]="value"
			(input)="onChange($event.target.value)"
			(blur)="onChange($event.target.value)"
			onlySelf="true"
		/>
		<!-- (blur)="onChange($event, input.value)"> -->
		<!--<ng-content></ng-content> -->

		<div class="input-group-append">
			<span [class.input-group-text]="hasRightTxt()"
				>{{ rightTxt }}
			</span>
		</div>
		<div class="input-group-append">
			<span [class.input-group-text]="hasRightTxt()">
				<i class="{{ rightIcon }}" *ngIf="hasRightIcon()"></i
			></span>
		</div>
	</div>
	<!--<span class="form-text text-muted"> {{ message }} </span> -->

	<span *ngIf="hasSuccess()" class="ok"><i class="fa fa-check"></i></span>

	<span class="help-block" *ngIf="hasError()"> {{ errorMessage }} </span>
</div>
<div
	*ngIf="hasSeparator()"
	class="kt-separator kt-separator--border-dashed kt-separator--space-lg"
></div>
