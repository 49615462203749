// Angular
import { Injectable, Injector } from '@angular/core';
// RxJS
import { Subject } from 'rxjs';
import { AuthGuard2 } from 'src/app/guard/auth-guard';
import { AxiosService } from 'src/app/_metronic/core/services/axios.service';
//import axiosHTTP from 'src/app/_metronic/shared/axios/axios';
import { environment } from '../../../../../environments/environment';
@Injectable()
export class AsideService {
  // Public properties
  onConfigUpdated$: Subject<any>;

  // Private properties
  private menuConfig: any = {};

  /**
   * Service Constructor
   */
  http: AxiosService;
  constructor(private injector: Injector, private auth: AuthGuard2) {
    this.http = this.injector.get<AxiosService>(AxiosService);
    // register on config changed event and set default config
    this.onConfigUpdated$ = new Subject();
  }

  /**
   * Returns the menuConfig
   */
  async getMenus() {
    const items: any = [];

    const result = await this.http
      .axiosHTTP()
      .get(`${environment.apiUrl}/generatemenu/1`); //, {params:{rules}});

    if (result && result.status === 200) {
      // //console.log(JSON.stringify(result.data));
      const { data } = result;

      //*
      let section = '';
      let submodel = '';
      let submodel2 = '';
      let list = [];
      let list2 = [];
      let icon = '';
      //   //console.log('DATA', data);
      await data.forEach((item) => {
        const addItem: any = {};

        if (submodel !== item.submodel) {
          if (list.length > 0) {
            if (list2.length > 0) {
              //  items.push({ submodel, icon, list, submodel2, list2 });
            } else {
              const itemIndex = items.findIndex(
                (item) => item.submodel === submodel
              );
              if (itemIndex !== -1) {
                // submodel exists in items array
                if (items[itemIndex].list) {
                  // increase list
                  items[itemIndex].list.push(...list);
                }
                if (items[itemIndex].list2) {
                  // increase list2
                  items[itemIndex].list2.push(...list2);
                }
              } else {
                //items.push({ submodel, submodel2, icon, list2 });
                items.push({ submodel, icon, list });
              }
            }

            //items.push({ submodel, icon, list });
            list = [];
            if (list2.length > 0) list2 = [];
            icon = '';
          }
          submodel = item.submodel;
          icon = item.icon;

          //          items.push({ submodel: item.submodel, icon: item.icon });
        }

        if (submodel2 !== item.submodel2) {
          if (list2.length > 0) {
            const itemIndex = items.findIndex(
              (item) => item.submodel === submodel
            );
            if (itemIndex !== -1) {
              // submodel exists in items array
              if (items[itemIndex].list) {
                // increase list
                items[itemIndex].list.push(...list);
              }
              if (items[itemIndex].list2) {
                // increase list2
                items[itemIndex].list2.push(...list2);
              }
            } else {
              items.push({ submodel, submodel2, icon, list2, list });
            }
            list = [];
            list2 = [];
            icon = '';
          }
          submodel2 = item.submodel2;
          icon = item.icon;

          //          items.push({ submodel: item.submodel, icon: item.icon });
        }

        if (section !== item.section) {
          section = item.section;

          items.push({ section: item.section, collapsed: true });
        }
        if (submodel === 'none' && list.length === 0) {
          addItem.title = item.title;
          addItem.translate = item.translate;
          addItem.root = true;
          addItem.icon = item.icon;
          addItem.page = item.page;
          items.push(addItem);
        }
        if (submodel !== 'none' && submodel2 === 'none') {
          addItem.title = item.title;
          addItem.translate = item.translate;
          addItem.root = true;
          addItem.page = item.page;
          list.push(addItem);
        } else if (submodel !== 'none' && submodel2 !== 'none') {
          addItem.title = item.title;
          addItem.translate = item.translate;
          addItem.root = true;
          addItem.page = item.page;
          list2.push(addItem);
        }
      });
      if (list.length > 0 && list2.length === 0) {
        items.push({ submodel, submodel2, icon, list });
      } else if (list.length > 0 && list2.length > 0) {
        items.push({ submodel, icon, list, submodel2, list2 });
      }
    }
    const defaults: any = {
      header: {
        self: {},
        items,
      },
      aside: {
        self: {},
        items,
      },
    };

    this.menuConfig = defaults;

    return this.menuConfig;
  }

  /**
   * Load config
   *
   * @param config: any
   */
  async loadConfigs(config: any) {
    // //console.log(JSON.stringify(config));
    // this.menuConfig = config; //defaults;
    // this.onConfigUpdated$.next(this.menuConfig);

    const items: any = [];
    const result = await this.http
      .axiosHTTP()
      .get(`${environment.apiUrl}/generatemenu/1`, {});

    if (result.status === 200) {
      // //console.log(JSON.stringify(result.data));
      const { data } = result;
      let section = '';

      await data.forEach((item) => {
        const addItem: any = {};
        if (section !== item.section) {
          section = item.section;

          items.push({ section: item.section });
        }
        addItem.title = item.title;
        addItem.translate = item.translate;
        addItem.root = true;
        addItem.icon = item.icon;
        addItem.page = item.page;
        items.push(addItem);
      });
    }
    const defaults: any = {
      header: {
        self: {},
        items,
      },
      aside: {
        self: {},
        items,
      },
    };

    this.menuConfig = defaults;
    // //console.log("config", this.menuConfig);
    this.onConfigUpdated$.next(this.menuConfig);
  }
}
