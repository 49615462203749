import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'kt-list2',

  templateUrl: './list2.component.html',
  // styleUrls: ["./input.component.scss"],
})
export class List2Component implements OnInit {
  @Input() title: String;
  col: Number = 4;
  @Input() items: any[] = [];

  @Output() listEmit: EventEmitter<any> = new EventEmitter();

  constructor(private router: Router) {}
  ngOnInit() {
    const tot = this.items.length;
    if (tot === 1) this.col = 12;
    else if (tot === 2) this.col = 6;

    ///   //console.log("TOT", this.col)
  }
  link(e) {
    if (e.link !== '') this.router.navigateByUrl(e.link, { state: e.param });
  }

  add(id, title) {
    this.listEmit.emit({
      type: 'add',
      id,
      title,
    });
  }

  del(id) {
    this.listEmit.emit({
      type: 'del',
      id,
    });
  }
}
