import { Component, ElementRef, Inject, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HeatmapSettings } from './heatmap-settings.model'; // Adjust the import path
import { FormsModule } from '@angular/forms';


@Component({
  selector: 'kt-heatmapsettings',
  templateUrl: './heatmap-settings-modal.component.html',
  styleUrls: ['./heatmap-settings-modal.component.scss']
})
export class HeatmapSettingsModalComponent implements OnChanges{
  @ViewChild('head1') head1Input!: ElementRef;
  @ViewChild('head2') head2Input!: ElementRef;
  @ViewChild('head3') head3Input!: ElementRef;
  @ViewChild('grid') grid!: ElementRef;
  @ViewChild('fontColor') fontColorInput!: ElementRef;
  @ViewChild('xaxispos') opposedPositionInput!: ElementRef;
  @ViewChild('xaxisrot') labelRotationInput!: ElementRef;
  tooltipLeft: number = 0;
  tooltipTop: number = 0;
  tooltipVisible: boolean = false;
  @Input() heatmapSettings: HeatmapSettings = {
    colorScheme: {startColor: '#873B3B', middleColor: '#FFFF00', endColor: '#31893F', fontColor: '#000000'},
    gridFormat: 0.1,
    xAxis: { opposedPosition: true, labelRotation: -90}
  };
  selectedColor = '#000000';
  heatmapInstance: any;

  constructor(
    public dialogRef: MatDialogRef<HeatmapSettingsModalComponent>,
    @Inject(MAT_DIALOG_DATA) public settings: HeatmapSettings
  ) {
      if(settings)
        this.heatmapSettings = settings;
  }
  
  applySettings(): void {
    const c1 = this.head1Input.nativeElement.value;
    const c2 = this.head2Input.nativeElement.value;
    const c3 = this.head3Input.nativeElement.value;
    const grid = this.grid.nativeElement.value;
    const font = this.fontColorInput.nativeElement.value;
    let xaispos = false;
    if(this.opposedPositionInput.nativeElement.checked) xaispos = true;
    const xaxisrot = this.labelRotationInput.nativeElement.value;

    this.heatmapSettings = {
      colorScheme: {
        startColor: c1,
        middleColor: c2,
        endColor: c3,
        fontColor: font
      },
      xAxis: {
        opposedPosition: xaispos,
        labelRotation: xaxisrot,
      },
      gridFormat: Number(grid)
    }
    this.dialogRef.close(this.heatmapSettings);
  }

  closeModal(): void {
    this.dialogRef.close();
  }
  ngOnChanges(changes: SimpleChanges): void {
    console.log("settings", changes.settings);
    console.log("settings", this.heatmapInstance);
    if (changes.settings && this.heatmapInstance) {

      // Apply color mapping settings only if heatmapInstance is defined
      const colorMapping = [
        { from: -Infinity, to: 0, color: this.heatmapSettings.colorScheme.startColor },
        { from: 0, to: this.heatmapSettings.colorScheme.startColor, color: this.heatmapSettings.colorScheme.middleColor },
        { from: this.heatmapSettings.colorScheme.middleColor, to: Infinity, color: this.heatmapSettings.colorScheme.endColor }
      ];
  
      this.applyColorMapping(colorMapping);
    }
  }
  
  applyColorMapping(colorMapping: any[]): void {
    
    if (this.heatmapInstance) {

      this.heatmapInstance.colorMapping(colorMapping);
      // ...
    }
  }
  showTooltip(event: Event) {
    const value = (event.target as HTMLInputElement).value;
    this.heatmapSettings.xAxis.labelRotation = Number(value);

  }
  showTooltipGrid(event: Event) {
    const value = (event.target as HTMLInputElement).value;
    this.heatmapSettings.gridFormat = Number(value);

  }


}