<div id="button">
	<dx-button
		stylingMode="contained"
		text="{{ nameText }}"
		type="normal"
		[width]="120"
		(onClick)="click($event)"
		validationGroup="grp"
		[useSubmitBehavior]="useSubmitBehavior"
		[disabled]="disabled"
	>
	</dx-button>
</div>
