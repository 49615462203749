<div class="row">
  <div class="card-body pt-12">
    <h3 class="card-title font-weight-bold text-dark">{{title}}</h3>
  </div>
</div>
<div class="row">
  <div *ngFor="let item of items" class="col-xl-{{col}}">
    <div class="card card-custom card-stretch gutter-b">
      <div class="card-header border-0 pt-5">
        <h3 class="card-title align-items-start flex-column">
          <span class="card-label font-weight-bolder text-dark">{{item.title}}</span>
          <!--<span class="text-muted mt-3 font-weight-bold font-size-sm">Pending 10 tasks</span>-->
        </h3>

      </div>
      <div class="card-body pt-8">
        <div *ngFor="let it of item.list" class="d-flex align-items-center mb-10">
          <div class="symbol symbol-40 symbol-light-{{it.colorIcon}} mr-5">
            <span class="symbol-label">
              <span class="svg-icon svg-icon-xl svg-icon-{{it.colorIcon}}">
                <i _ngcontent-wau-c121="" class="fa {{it.icon}} icon-3x  svg-icon text-{{it.colorIcon}}"></i>
              </span>
            </span>
          </div>
          <div class="d-flex flex-column font-weight-bold">
            <a (click)="link(it)" class="text-dark text-hover-primary mb-1 font-size-lg"
              style="cursor: pointer">{{it.title}}</a>
            <span class="text-muted">{{it.subTitle}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>