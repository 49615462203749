import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

export class ResultData {
  msg: string;
}

@Injectable()
export class MessageService {
  private outputSubject: BehaviorSubject<ResultData> = new BehaviorSubject(null);

  output : Observable<ResultData> = this.outputSubject.asObservable();

  send(data: ResultData) {
    this.outputSubject.next(data);
  }
}
