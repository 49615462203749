export function isNotUndefined(val) {
  return typeof val !== 'undefined';
}

export function monthYear(d) {
  const date = new Date(d);

  // eslint-disable-next-line default-case
  switch (date.getMonth()) {
    case 0:
      return `Janeiro/${date.getFullYear()}`;
    case 1:
      return `Fevereiro/${date.getFullYear()}`;
    case 2:
      return `Março/${date.getFullYear()}`;
    case 3:
      return `Abril/${date.getFullYear()}`;
    case 4:
      return `Maio/${date.getFullYear()}`;
    case 5:
      return `Junho/${date.getFullYear()}`;
    case 6:
      return `Julho/${date.getFullYear()}`;
    case 7:
      return `Agosto/${date.getFullYear()}`;
    case 8:
      return `Setembro/${date.getFullYear()}`;
    case 9:
      return `Outubro/${date.getFullYear()}`;
    case 10:
      return `Novembro/${date.getFullYear()}`;
    case 11:
      return `Dezembro/${date.getFullYear()}`;
    default:
      return '';
    // code block
  }
}
