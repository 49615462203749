import { enableProdMode } from '@angular/core';

import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { registerLicense } from '@syncfusion/ej2-base';
registerLicense('ORg4AjUWIQA/Gnt2V1hiQlRPd11dXmJWd1p/THNYflR1fV9DaUwxOX1dQl9gSXZTf0RnW3dbcXxdQ2Q=');
  //'Ngo9BigBOggjHTQxAR8/V1NGaF1cWGhBYVJ1WmFZfV1gd19CaFZUQWY/P1ZhSXxQdk1jX35bdHxQQ2RUVEY=');
// import { environment } from './environments/environment';

// if (environment.production) {

enableProdMode();
// }

platformBrowserDynamic()
  .bootstrapModule(AppModule)

  .catch((err) => console.error(err));
