<div class="row">
  <div class="col-lg-12">
    <div class="card card-custom gutter-b horizontal-size">
      <div class="card-header">
        <div class="card-title">
          <h3 class="card-label">
            {{ title }}
            <small></small>
          </h3>
        </div>
      </div>
      <div class="card-body">
        <div class="kt-portlet__body">
          <div *ngIf="hasInfo()" class="form-group form-group-last">
            <div class="alert alert-secondary" role="alert">
              <div class="alert-icon">
                <i class="{{ infoIcon }} kt-font-brand"></i>
              </div>
              <div class="alert-text">
                {{ infoText }}
              </div>
            </div>
          </div>
          <form novalidate class="kt-form kt-form--label-right" novalidate>
            <ng-content></ng-content>
          </form>
        </div>
      </div>
      <div class="card-footer"></div>
    </div>
  </div>
</div>

<!-- 
<div
  class="kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid"
>
  <div class="row">
    <div class="col-md-{{ cols }}">
      <div class="kt-portlet">
        <div class="kt-portlet__head">
          <div class="kt-portlet__head-label">
            <h3 class="kt-portlet__head-title">{{ title }}</h3>
          </div>
        </div>
        <div class="kt-portlet__body">
          <div *ngIf="hasInfo()" class="form-group form-group-last">
            <div class="alert alert-secondary" role="alert">
              <div class="alert-icon">
                <i class="{{ infoIcon }} kt-font-brand"></i>
              </div>
              <div class="alert-text">
                {{ infoText }}
              </div>
            </div>
          </div>
          <form novalidate class="kt-form kt-form--label-right" novalidate>
            <ng-content></ng-content>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
-->
