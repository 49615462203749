import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class DataShareService {
    rowIndex: any;

    pageIndex: any;

    sortingOrder = [];

    rowKey: any;

    updateRowIndex(index) { this.rowIndex = index; }

    get RowIndex() { return this.rowIndex; }

    updatePageIndex(index) { this.pageIndex = index; }

    updateSortingOrder(obj) { this.sortingOrder = obj; }

    updateRowKey(index) { this.rowKey = index; }

    private popupCloseObserable = new BehaviorSubject<any>(false);

    setPopupCloseBehavior(behave) {
      this.popupCloseObserable.next(behave);
    }

    getPopupCloseBehavior(): Observable<any> {
      return this.popupCloseObserable.asObservable();
    }
}
