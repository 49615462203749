import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'kt-collapse',
  templateUrl: './collapse.component.html',
  styleUrls: ['./collapse.component.scss'],
})
export class CollapseComponent implements OnInit {
  @Input() id_collapse: string = '1';

  @Input() title: string = 'UISE Colapse';

  constructor() {}

  ngOnInit() {
    setTimeout(() => {
      const t = this;
      $('.panel-group').on('hidden.bs.collapse', t.toggleIcon);
      $('.panel-group').on('shown.bs.collapse', t.toggleIcon);
    }, 200);
    // this.cdr.detectChanges();
  }
  toggleIcon(e) {
    //  //console.log(e.target);
    $(e.target)
      .prev('.panel-heading')
      .find('.short-full')
      .toggleClass('ki-arrow-up');
  }
}
