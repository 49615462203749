<h2>Heatmap Settings</h2>

<div class="row">
  <div class="col-md-6">
    <div>
      <input #head1 id="head1" type="color" [value]="heatmapSettings.colorScheme.startColor" />
      <label for="head1">Start Color</label>
    </div>
    <div>
      <input #head2 type="color" value="{{heatmapSettings.colorScheme.middleColor}}" />
      <label for="head2">Middle Color</label>
    </div>
  </div>
  <div class="col-md-6">
    <div>
      <input #head3 type="color" value="{{heatmapSettings.colorScheme.endColor}}" />
      <label for="head3">End Color</label>
    </div>
    <div>
      <input #fontColor type="color" id="font" value="{{heatmapSettings.colorScheme.fontColor}}" />
      <label for="font">Font Color</label>
    </div>
  </div>
</div>
<hr/>
<div class="row">
  <div class="col-md-12">
    <div>
      <input #grid (input)="showTooltipGrid($event)" type="range" min="0.0" max="3.0" step="0.1" value="{{heatmapSettings.gridFormat}}">
      <label for="grid">Grid style Border({{heatmapSettings.gridFormat}})</label>
    </div>
  </div>
</div>
<hr/>
<div class="row">
  <div class="col-md-6">
    <div>
      <input #xaxispos type="checkbox" [checked]="heatmapSettings.xAxis.opposedPosition" [value]="heatmapSettings.xAxis.opposedPosition">
      <label title="bottom - false | top - true">xAxis Bottom/Top</label> 
    </div>
    
   </div> 
   <div class="col-md-6">
    <div>
      <input (input)="showTooltip($event)"
       #xaxisrot type="range" min="-180" max="180" step="1" value="{{heatmapSettings.xAxis.labelRotation}}">
      <label for="xaxisrot">xAxis rotation({{ heatmapSettings.xAxis.labelRotation }})</label>
      
    </div>
  </div>
</div>
<div class="row">
  <div class="col-md-6 text-left">
    <button (click)="applySettings()">Apply</button>
  </div>
  <div class="col-md-6 text-right">
    <button (click)="closeModal()">Close</button>
  </div>
</div>