import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from '@angular/router';
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';
import { KeycloakProfile } from 'keycloak-js';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard2 extends KeycloakAuthGuard {
  private userID: number = 0;
 
  constructor(
    protected readonly router: Router,
    protected readonly keycloak: KeycloakService
  ) {
    super(router, keycloak);
  }

  async isAccessAllowed(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean | UrlTree> {
    if (!this.authenticated) {
      await this.keycloak.login({
        redirectUri: window.location.origin + state.url,
      });
    }

    return this.authenticated;
  }

  loadProfile(): Promise<KeycloakProfile> {
    return new Promise<any>(async (resolve, reject) => {
      if (await this.keycloak.isLoggedIn) {
        this.keycloak
          .loadUserProfile()
          .then((data) => {
            resolve(<KeycloakProfile>data);
          })
          .catch(() => {
            reject('Failed to load profile');
          });
      } else {
        reject('Not loggen in');
      }
    });
  }

  checkRoles(rule: string): Boolean {
    return this.keycloak.isUserInRole(rule);
  }

  async token() {
    return await this.keycloak.getToken();
  }
  logout() {
    this.keycloak.logout();
  }
  refreshToken() {
    return this.keycloak.getKeycloakInstance().refreshToken;
  }

  loadRoles(): Promise<any> {
    return new Promise<any>(async (resolve, reject) => {
      if (await this.keycloak.isLoggedIn) {
        resolve(this.keycloak.getUserRoles());
      } else {
        reject('Not loggen in');
      }
    });
  }
}
