import {
  Input,
  Component,
  Output,
  EventEmitter,
  ViewChild,
} from '@angular/core';
import { DxTabsComponent } from 'devextreme-angular';

// import { Tab, Longtab, Service } from './app.service';

@Component({
  selector: 'kt-tab',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
})
export class TabsComponent {
  @Input() tabs: Array<any> = [{ content: '1' }];

  @Output() tabEmit: EventEmitter<any> = new EventEmitter();

  @ViewChild('apiTabs', { static: false }) dataTab: DxTabsComponent;
  tabContent: string;

  constructor() {}

  ngOnChanges() {
    //console.log('Tabs>>', this.tabs);
  }

  selectTab(e) {
    ////console.log('CONTENT TABSSS', e);
    this.tabEmit.emit({
      data: this.tabs[e.itemIndex].content,
      id: this.tabs[e.itemIndex].id,
    });
  }
}
