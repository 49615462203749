import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
@Injectable()
export class ExcelService {
  constructor() { }

  public exportAsExcelFile(json: any[], excelFileName: string): void {
    let worksheet: XLSX.WorkSheet;
    const workbook: XLSX.WorkBook = { SheetNames: [], Sheets: {} };
    let excelBuffer: any;
    const sheetNames = [];
    for (let i = 0; i < json.length; i++) {
      worksheet = XLSX.utils.json_to_sheet(json[i]);
      const sheetId = i + 1;
      workbook.SheetNames.push(`Sheet${sheetId}`);
      workbook.Sheets[`Sheet${sheetId}`] = worksheet;
    }
    excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(data, `${fileName}_export${EXCEL_EXTENSION}`);
  }
}
