<label *ngIf="type === 'checkbox'" class="kt-checkbox {{ ktClass }}">
	<input type="{{ type }}" /> {{ label }}
	<span></span>
</label>

<label *ngIf="type === 'radio'" class="kt-radio {{ ktClass }}">
	<input type="{{ type }}" name="{{ name }}" value="{{ data }}" />
	{{ label }}
	<span></span>
</label>
