<!--begin::Header-->
<ng-container *ngIf="extrasNotificationsDropdownStyle === 'dark'">
  <div class="d-flex flex-column pt-12 bg-dark-o-5 rounded-top">
    <!--begin::Title-->
    <h4 class="d-flex flex-center">
      <span class="text-dark">Notifications</span>
      <span
        class="btn btn-text btn-success btn-sm font-weight-bold btn-font-md ml-2"
        >{{total}}</span
      >
    </h4>
    <!--end::Title-->

    <!--begin::Tabs-->
    <ul
      class="nav nav-bold nav-tabs nav-tabs-line nav-tabs-line-3x nav-tabs-primary mt-3 px-8"
      role="tablist"
    >
      <li class="nav-item">
        <a
          class="nav-link cursor-pointer"
          data-toggle="tab"
          (click)="setActiveTabId('topbar_notifications_notifications')"
          [ngClass]="getActiveCSSClasses('topbar_notifications_notifications')"
          >Alerts</a
        >
      </li>
      <li class="nav-item">
        <a
          class="nav-link cursor-pointer"
          data-toggle="tab"
          (click)="setActiveTabId('topbar_notifications_events')"
          [ngClass]="getActiveCSSClasses('topbar_notifications_events')"
          >Errors</a
        >
      </li>
      <li class="nav-item">
        <a
          class="nav-link cursor-pointer"
          data-toggle="tab"
          (click)="setActiveTabId('topbar_notifications_logs')"
          [ngClass]="getActiveCSSClasses('topbar_notifications_logs')"
          >Infos</a
        >
      </li>
    </ul>
    <!--end::Tabs-->
  </div>
</ng-container>
<ng-container *ngIf="extrasNotificationsDropdownStyle === 'light'">
  <div
    class="d-flex flex-column pt-12 bgi-size-cover bgi-no-repeat rounded-top"
    style="background-image: url('./assets/media/misc/bg-1.jpg');"
  >
    <!--begin::Title-->
    <h4 class="d-flex flex-center rounded-top">
      <span class="text-white">Notifications</span>
      <span
        class="btn btn-text btn-success btn-sm font-weight-bold btn-font-md ml-2"
        >{{total}}</span
      >
    </h4>
    <!--end::Title-->

    <!--begin::Tabs-->
    <ul
      class="nav nav-bold nav-tabs nav-tabs-line nav-tabs-line-3x nav-tabs-line-transparent-white nav-tabs-line-active-border-success mt-3 px-8"
      role="tablist"
    >
      <li class="nav-item">
        <a
          class="nav-link cursor-pointer"
          data-toggle="tab"
          (click)="setActiveTabId('topbar_notifications_notifications')"
          [ngClass]="getActiveCSSClasses('topbar_notifications_notifications')"
          >Alertas</a
        >
      </li>
      <li class="nav-item">
        <a
          class="nav-link cursor-pointer"
          data-toggle="tab"
          (click)="setActiveTabId('topbar_notifications_events')"
          [ngClass]="getActiveCSSClasses('topbar_notifications_events')"
          >Erros</a
        >
      </li>
      <li class="nav-item">
        <a
          class="nav-link cursor-pointer"
          data-toggle="tab"
          (click)="setActiveTabId('topbar_notifications_logs')"
          [ngClass]="getActiveCSSClasses('topbar_notifications_logs')"
          >Infos</a
        >
      </li>
    </ul>
    <!--end::Tabs-->
  </div>
</ng-container>
<!--end::Header-->

<!--begin::Content-->
<div class="tab-content">
  <!--begin::Tabpane-->
  <div
    class="tab-pane"
    id="topbar_notifications_notifications"
    role="tabpanel"
    [ngClass]="getActiveCSSClasses('topbar_notifications_notifications')"
  >
    <!--begin::Scroll-->
    <div
      class="scroll pr-7 mr-n7 ps"
      style="position: relative; max-height: 300px;"
      perfectScrollbar
    >
    <div *ngIf="alertNot.length<=0" class="d-flex flex-center text-center text-muted min-h-200px">
      empty.
    </div>
    <!--end::Nav-->
      <!--begin::Item-->
      <div *ngFor="let item of alertNot" class="d-flex align-items-center mb-6">
        <!--begin::Symbol-->
        <div class="symbol symbol-40 symbol-light-warning mr-5">
          <span class="symbol-label">
            
            <i class="svg-icon svg-icon-xl svg-icon-primary flaticon-danger text-warning"></i>
          </span>
        </div>
        <!--end::Symbol-->
        
        <!--begin::Text-->
        <div class="cursor-pointer d-flex flex-column font-weight-bold">
          <a (click)="openRoute(item.route)" class="text-dark text-hover-primary mb-1 font-size-lg"
            >{{item.title}}</a
          >
          <span class="text-muted">{{item.text}}</span>
          <span class="text-mutedDate">{{formatDate(item.created_date)}}</span>
        </div>
        <!--end::Text-->
        <div class="symbol symbol-40 symbol-light-warning mr-5">
            <i (click)="remove(item.id_notification_to)" class="btn btn-light-primary flaticon2-trash"></i>
        </div>
      </div>
      <!--end::Item-->
      
    </div>
    <!--end::Scroll-->

    <!--begin::Action-->
    <div class="d-flex flex-center pt-7">
      <a (click)="closeDialog()" class="btn btn-light-primary font-weight-bold text-center"
        >Close</a>
    </div>
    
    <!--end::Action-->
  </div>
  <!--end::Tabpane-->

  <!--begin::Tabpane-->
  <div
    class="tab-pane"
    id="topbar_notifications_events"
    role="tabpanel"
    [ngClass]="getActiveCSSClasses('topbar_notifications_events')"
  >
    <!--begin::Scroll-->
    <div
      class="scroll pr-7 mr-n7 ps"
      style="position: relative; max-height: 300px;"
      perfectScrollbar
    >
    <div *ngIf="eventNot.length<=0" class="d-flex flex-center text-center text-muted min-h-200px">
      empty.
    </div>
    <!--end::Nav-->
      <!--begin::Item-->
      <div *ngFor="let item of eventNot" class="d-flex align-items-center mb-6">
        <!--begin::Symbol-->
        <div class="symbol symbol-40 symbol-light-danger mr-5">
          <span class="symbol-label">
            
            <i class="svg-icon svg-icon-xl svg-icon-primary flaticon-danger text-danger"></i>
          </span>
        </div>
        <!--end::Symbol-->

        <!--begin::Text-->
        <div class="d-flex flex-column font-weight-bold">
          <a (click)="openRoute(item.route)" class="text-dark text-dark text-hover-primary mb-1 font-size-lg"
            >{{item.title}}</a
          >
          <span class="text-muted">{{item.text}}</span>
          <span class="text-mutedDate">{{formatDate(item.created_date)}}</span>
        </div>
        <!--end::Text-->
      </div>
      <!--end::Item-->
      
    </div>
    <!--end::Scroll-->

    <!--begin::Action-->
    <div class="d-flex flex-center pt-7">
      <a (click)="closeDialog()" class="btn btn-light-primary font-weight-bold text-center"
        >Close</a>
    </div>
  </div>
  <!--end::Tabpane-->

  <!--begin::Tabpane-->
  <div
    class="tab-pane"
    id="topbar_notifications_logs"
    role="tabpanel"
    [ngClass]="getActiveCSSClasses('topbar_notifications_logs')"
  >
<!--begin::Scroll-->
<div
class="scroll pr-7 mr-n7 ps"
style="position: relative; max-height: 300px;"
perfectScrollbar
>
<div *ngIf="infoNot.length<=0" class="d-flex flex-center text-center text-muted min-h-200px">
empty.
</div>
<!--end::Nav-->
<!--begin::Item-->
<div *ngFor="let item of infoNot" class="d-flex align-items-center mb-6">
  <!--begin::Symbol-->
  <div class="symbol symbol-40 symbol-light-info mr-5">
    <span class="symbol-label">
      
      <i class="svg-icon svg-icon-xl svg-icon-info flaticon-info text-danger"></i>
    </span>
  </div>
  <!--end::Symbol-->

  <!--begin::Text-->
  <div class="d-flex flex-column font-weight-bold">
    <a (click)="openRoute(item.route)" class="text-dark text-hover-primary mb-1 font-size-lg"
      >{{item.title}}</a
    >
    <span class="text-muted">{{item.text}}</span>
    <span class="text-mutedDate">{{formatDate(item.created_date)}}</span>
  </div>
  <!--end::Text-->
</div>
<!--end::Item-->

</div>
<!--end::Scroll-->

<!--begin::Action-->
<div class="d-flex flex-center pt-7">
<a (click)="closeDialog()" class="btn btn-light-primary font-weight-bold text-center"
  >Close</a>
</div>
  </div>
  <!--end::Tabpane-->
</div>
<!--end::Content-->
