import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'kt-list',

  templateUrl: './list.component.html',
  // styleUrls: ["./input.component.scss"],
})
export class ListComponent implements OnInit {
  @Input() title: String;
  col: Number = 4;
  @Input() items: any[] = [];

  constructor(private router: Router) {}
  ngOnInit() {
    const tot = this.items.length;
    if (tot === 1) this.col = 12;
    else if (tot === 2) this.col = 6;

    ///   //console.log("TOT", this.col)
  }
  link(e) {
    if (e.link !== '') this.router.navigateByUrl(e.link, { state: e.param });
  }
}
