import { Component} from '@angular/core';
import { CdkDragRelease,CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';


@Component({
  selector: 'kt-movable-panel',
  templateUrl: './movable-panel.component.html',
  styleUrls: ['./movable-panel.component.scss']
})
export class MovablePanelComponent {
  constructor() {}
  onDrop(event: CdkDragDrop<any[]>) {
    const prevIndex = event.item.data;
    const newIndex = event.currentIndex;
    
    // Implement collision detection logic
    //if () {
      // Adjust panel positions to avoid collision
    //} else {
      moveItemInArray(event.container.data, prevIndex, newIndex);
   // }
  }
  onResize(event: any) {
    const newWidth = event.width;
    const newHeight = event.height;
    
    // Perform collision detection during resizing
    if (this.checkForCollision(event.source.element.nativeElement, newWidth, newHeight)) {
      // You can adjust dimensions or prevent resizing here
      event.preventDefault();
    } else {
      event.source.element.nativeElement.style.width = newWidth + 'px';
      event.source.element.nativeElement.style.height = newHeight + 'px';
    }
  }
  
  private checkForCollision(element: HTMLElement, newWidth: number, newHeight: number): boolean {
    // Implement collision detection logic here
    // Compare the element's position and dimensions with other panels or collision zones
    // Return true if collision is detected, false otherwise
    return false; // Replace with actual collision detection logic
  }
}