import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import * as d3 from 'd3';

@Component({
  selector: 'kt-hm',
  templateUrl: './heatmap2.component.html',
  styleUrls: ['./heatmap2.component.scss']
})
export class Heatmap2Component implements OnInit {
  

  @ViewChild('heatmapContainer', { static: true }) private heatmapContainer: ElementRef;


  private data: number[][] = [
    [1,2,3,4,5,6,7,8,9],
    [1,2,3,4,5,6,7,8,9],
    [1,2,3,4,5,6,7,8,9],
    [1,2,3,4,5,6,7,8,9],

    
  ];

  private margin = { top: 40, right: 40, bottom: 80, left: 80 };

  constructor() {}

  ngOnInit(): void {
    this.createHeatmap();
    window.addEventListener('resize', () => this.createHeatmap());
  }
  private createHeatmap(): void {
    d3.select(this.heatmapContainer.nativeElement).select('svg').remove();

    const containerWidth = this.heatmapContainer.nativeElement.clientWidth;
    const width = containerWidth - this.margin.left - this.margin.right;
    const height = 80 * this.data.length;

    const numRows = this.data.length;
    const svg = d3
      .select(this.heatmapContainer.nativeElement)
      .append('svg')
      .attr('viewBox', `0 0 ${containerWidth} ${height + this.margin.top + this.margin.bottom}`)
      .append('g')
      .attr('transform', `translate(${this.margin.left},${this.margin.top})`);

    const colorScale = d3.scaleLinear()
      .domain([0, 5, 10,30])  // Define your value ranges
      .range(['#000FFF', 'yellow', 'green', '#AF1AFF']);  // Corresponding colors for each range

      const cellSize = Math.min(width / this.data[0].length, height / this.data.length); // 5 columns and 3 rows

      const flattenedData = [];

      for (const row of this.data) {
        for (const value of row) {
          flattenedData.push(value);
        }
      }

      const cells = svg.selectAll('.cell')
      .data(flattenedData)
      .enter().append('rect')
      .attr('class', 'cell')
      .attr('x', (d, i) => (i % this.data[0].length) * cellSize)
      .attr('y', (d, i) => Math.floor(i / this.data[0].length) * cellSize)
      .attr('width', cellSize)
      .attr('height', cellSize)
      .style('fill', d => colorScale(d));
    
    // Adjust the x-coordinate to move the grid lines to the right
    const yAxisLabels = svg.append('g')
      .selectAll('.yAxisLabel')
      .data(this.data)
      .enter().append('text')
      .attr('class', 'yAxisLabel')
      .attr('x', -20) // Adjust this value based on your design
      .attr('y', (d, i) => i * cellSize + cellSize / 2)
      .attr('dy', '.35em')
      .attr('text-anchor', 'end')
      .text((d, i) => `TCGA-50-5933-21${i + 1}`);

  const heatmapHeight = numRows * cellSize;    
  const xAxisLabelRotation = -90; 
  const xAxisLabelMargin = 30; // Set the desired margin for rotated labels

// Calculate the height adjustment including margin based on the rotation angle and label length
const labelHeightAdjustment = (Math.abs(Math.sin(xAxisLabelRotation * (Math.PI / 180))) * (xAxisLabelMargin + 10)); // Adjust this value as needed
  console.log(numRows, heatmapHeight, cellSize, this.data[0].length)
   // Adding x-axis labels
   const xAxisLabels = svg.append('g')
   .selectAll('.xAxisLabel')
   .data(this.data[0])
   .enter().append('text')
   .attr('class', 'xAxisLabel')
   .attr('x', (d, i) => i * cellSize + cellSize / 2)
   .attr('y', heatmapHeight + labelHeightAdjustment)
   .attr('dy', '.71em')
   .attr('text-anchor', 'middle')
   .attr('transform', (d, i) => `rotate(${xAxisLabelRotation}, ${i * cellSize + cellSize / 2}, ${heatmapHeight + labelHeightAdjustment})`) // Apply rotation
   .text((d, i) => `Column ${i + 1}`);



 const cellNumbers = svg.selectAll('.cell-number')
      .data(flattenedData)
      .enter().append('text')
      .attr('class', 'cell-number')
      .attr('x', (d, i) => (i % this.data[0].length) * cellSize + cellSize / 2)
      .attr('y', (d, i) => Math.floor(i / this.data[0].length) * cellSize + cellSize / 2)
      .attr('dy', '.35em')
      .attr('text-anchor', 'middle')
      .text(d => d);

    
 
    // ... Rest of the code ...
  }






  
}
