<div class="input-group">
	<div class="input-group-append">
		<span [class.input-group-text]="hasLeftIcon()">
			<i class="{{ leftIcon }}" *ngIf="hasLeftIcon()"></i
		></span>
	</div>
	<div class="input-group-prepend">
		<span [class.input-group-text]="hasLeftTxt1()">{{ leftTxt1 }}</span>
		<span [class.input-group-text]="hasLeftTxt2()">{{ leftTxt2 }}</span>
	</div>

	<!-- =============================== -->
	<div style="width: 100%" class=" {{ isValidClass }} ">
		<select class="form-control kt-select22" id="kt_select2_4" name="sele1">
			<option value="" disabled selected>{{ defaultText }}</option>

			<option
				[value]="item.id"
				*ngFor="let item of dropDownListArray; let i = index"
			>
				{{ item.text }}
			</option>
		</select>
	</div>
	<!-- ===============================-->

	<!--<ng-select2
			id="{{ selectId }}"
			aria-describedby="basic-addon1"
			[data]="dropDownListArray"
			[placeholder]="'Please select an item...'"
			width="100%"
		></ng-select2>-->

	<!--<select
		name="{{ selectName }}"
		id="{{ selectId }}"
		aria-describedby="basic-addon1"
		class="form-control {{ isValidClass }}"
	>
		<option value="">{{ defaultText }}</option>
		<option [value]="item.id" *ngFor="let item of dropDownListArray">
			{{ item.text }}
		</option>
	</select> -->

	<div class="input-group-append">
		<span [class.input-group-text]="hasRightTxt()">{{ rightTxt }} </span>
	</div>
	<div class="input-group-append">
		<span [class.input-group-text]="hasRightTxt()">
			<i class="{{ rightIcon }}" *ngIf="hasRightIcon()"> </i>
		</span>
	</div>
</div>
<!--<span class="form-text text-muted"> {{ message }} </span> -->
<div msg>
	<span *ngIf="hasSuccess()" class="ok"><i class="fa fa-check"></i></span>

	<span class="help-block" *ngIf="hasError()"> {{ errorMessage }} </span>
</div>
