import { Input, Component, Output, EventEmitter } from '@angular/core';
import { GenericService } from '../../../core/services/generic.service';
// import { Tab, Longtab, Service } from './app.service';

@Component({
  selector: 'kt-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss'],
})
export class PopupComponent {
  @Input() headerData = '';

  @Input() popUpVisible = false;

  @Output() popUpClose: EventEmitter<any> = new EventEmitter();

  constructor(private service: GenericService) {}

  onHiding(e) {
    //console.log('Popup Hiding', e);
    this.popUpClose.emit();
  }
}
