import { Location } from '@angular/common';
import { Component, OnInit, ChangeDetectorRef, NgZone } from '@angular/core';
import { LayoutService } from '../../../../_metronic/core';
import { AsideService } from './aside.service';
@Component({
  selector: 'app-aside',
  templateUrl: './aside.component.html',
  styleUrls: ['./aside.component.scss'],
  providers: [AsideService],
})
export class AsideComponent implements OnInit {
  disableAsideSelfDisplay: boolean;
  submodelCollapsed: boolean = false;
  headerLogo: string;

  brandSkin: string;

  ulCSSClasses: string;

  location: Location;

  asideMenuHTMLAttributes: any = {};

  asideMenuCSSClasses: string;

  asideMenuDropdown;

  brandClasses: string;

  asideMenuScroll = 1;

  asideSelfMinimizeToggle = true;

  items: any;
  //showMenu: any[] = [];
  menuLink: string = "'./assets/media/svg/icons/Clothes/Cap.svg";

  constructor(
    private layout: LayoutService,
    private loc: Location,
    private service: AsideService,
    private cdr: ChangeDetectorRef,
    private zone: NgZone
  ) {}

  async ngOnInit() {
    this.items = await this.service.getMenus();
    this.items = this.items.header.items;
    this.items.map((e, index) =>{
     // this.showMenu.push("menu-item show");
      e["cssClass"] = "menu-item show";
    });

    this.cdr.detectChanges();
    // //console.log('ppp', this.items);
    // load view settings
    this.disableAsideSelfDisplay =
      this.layout.getProp('aside.self.display') === false;
    this.brandSkin = this.layout.getProp('brand.self.theme');
    this.headerLogo = this.getLogo();
    this.ulCSSClasses = this.layout.getProp('aside_menu_nav');
    this.asideMenuCSSClasses = this.layout.getStringCSSClasses('aside_menu');
    this.asideMenuHTMLAttributes = this.layout.getHTMLAttributes('aside_menu');
    this.asideMenuDropdown = this.layout.getProp('aside.menu.dropdown')
      ? '1'
      : '0';
    this.brandClasses = this.layout.getProp('brand');
    this.asideSelfMinimizeToggle = this.layout.getProp(
      'aside.self.minimize.toggle'
    );
    this.asideMenuScroll = this.layout.getProp('aside.menu.scroll') ? 1 : 0;
    // this.asideMenuCSSClasses = `${this.asideMenuCSSClasses} ${this.asideMenuScroll === 1 ? 'scroll my-4 ps ps--active-y' : ''}`;
    // Routing

    this.location = this.loc;
  }
  toggleSubmodelCollapse() {
    this.submodelCollapsed = !this.submodelCollapsed;
  }
  private getLogo() {
    if (this.brandSkin === 'light') {
      return './assets/media/logos/logo-dark.png';
    }
    return './assets/media/logos/logo-light.png';
  }
  clickMenu(value: boolean, index: number) {
      let css = "menu-item show";
      if(!value)css = "menu-item hidden";
       let found = false;
       let sector = "";
       this.items.map((e, line) => {
          if(index === line) {
             found = true;
             sector = e.section;
          }
          if(found && !e.section) e.cssClass = css;
          if(e.section && sector !== e.section) found = false;
       })

       this.items[index].cssClass = css;
       this.cdr.detectChanges();
  }

}
