import { Component, OnInit } from '@angular/core';
import { LayoutService } from '../../../../../core';
import { MatDialogRef } from '@angular/material/dialog';
import { SubheaderService } from '../../../subheader/_services/subheader.service';
import { Router } from '@angular/router';
//declare let io: any;
//import Swal from 'sweetalert2/dist/sweetalert2.js';
//import { Router } from '@angular/router';
//
//import { AxiosService } from 'src/app/_metronic/core/services/axios.service';
@Component({
  selector: 'app-notifications-dropdown-inner',
  templateUrl: './notifications-dropdown-inner.component.html',
  styleUrls: ['./notifications-dropdown-inner.component.scss'],
})
export class NotificationsDropdownInnerComponent implements OnInit {
  extrasNotificationsDropdownStyle: 'light' | 'dark' = 'dark';

  activeTabId:
    | 'topbar_notifications_notifications'
    | 'topbar_notifications_events'
    | 'topbar_notifications_logs' = 'topbar_notifications_notifications';
  
  alertNot : any[]=[];
  infoNot: any[]=[];
  eventNot: any[]=[];
  total: number = 0;
  constructor(private dialogRef: MatDialogRef<NotificationsDropdownInnerComponent>,
     private subheader: SubheaderService, private router: Router) // private router: Router, // private layout: LayoutService,
  // private http: AxiosService
  {}
  closeDialog() {
    this.dialogRef.close();
  }
  async ngOnInit() {
       await this.getNotifications();

     }
  
  async getNotifications() {
    const res = await this.subheader.getNotifications();
    if(res.status===200) {
       const notifications = res.data.result;
       this.total = notifications.length;
       this.eventNot = notifications.filter(e => e.id_section===1);
       this.alertNot = notifications.filter(e => e.id_section===2);
       this.infoNot = notifications.filter(e => e.id_section===3);
    }
  }
  formatDate(date: string){

const now = new Date();
const year = now.getFullYear();

const formattedDate = (new Date(date).getFullYear() === year) 
  ? new Date(date).toLocaleDateString('pt-BR', { day: 'numeric', month: 'long' })
  : new Date(date).toLocaleDateString('pt-BR', {day: 'numeric', month: 'long', year: 'numeric'});

const formattedTime = new Date(date).toLocaleTimeString('pt-BR', { hour: '2-digit', minute:'2-digit' });
    return `${formattedDate} ${formattedTime}`;
  }
  setActiveTabId(tabId) {
    this.activeTabId = tabId;
  }
  
  getActiveCSSClasses(tabId) {
    if (tabId !== this.activeTabId) {
      return '';
    }
    return 'active show';
  }
  openRoute(route: string) {
    this.closeDialog();
    this.router.navigateByUrl(route, { });
  }
  async remove(id: number) {
    const result = await this.subheader.delNotifications(id);
    await this.getNotifications();
  }
}
